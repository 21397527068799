import React, { FunctionComponent } from 'react';
import { FlightOverflowMenu } from '@flybits/webapp-design-system-react';
import { FlightCard } from '@flybits/webapp-design-system-react';
import './ConnectorsListItem.scss';
import { Connector } from '../../model/connectors/connectors';

interface Props {
  item: Connector;
  handleRedirectToDatasourceSharingPage: (connectorId: string) => void;
}

const ConnectorsListItem: FunctionComponent<Props> = (props: Props) => {
  const { item, handleRedirectToDatasourceSharingPage } = props;
  const MAIN_CLASS = 'connector-list-item';
  const CARD_CLASS = `${MAIN_CLASS}__card`;
  const HEADER_CLASS = `${CARD_CLASS}__header`;
  const TEXT_CLASS = `${CARD_CLASS}__text`;
  const TITLE_CLASS = `${TEXT_CLASS}__title`;
  const PROVIDER_NAME_CLASS = `${TEXT_CLASS}__provider-name`;
  const DESCRIPTION_CLASS = `${TEXT_CLASS}__description`;
  const IMAGE_CLASS = `${CARD_CLASS}__image`;
  const IMAGE_WRAPPER_CLASS = `${CARD_CLASS}__image-wrapper`;
  const OVERFLOW_CLASS = `${CARD_CLASS}__overflow`;

  const header = (
    <div className={HEADER_CLASS}>
      <div className={TITLE_CLASS}> {item.name}</div>
      <div className={PROVIDER_NAME_CLASS}> {`By ${item.providerName}`}</div>
      <div className={DESCRIPTION_CLASS}> {item.description}</div>
    </div>
  );

  const initiateRedirect = () => {
    handleRedirectToDatasourceSharingPage(item.id);
  };

  return (
    <FlightCard
      className={CARD_CLASS}
      header={header}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick={initiateRedirect}
      image={
        <div className={IMAGE_WRAPPER_CLASS}>
          <img className={IMAGE_CLASS} src={item.imageUrl} alt="connector thumbnail"></img>
        </div>
      }
      actionables={
        <FlightOverflowMenu
          className={OVERFLOW_CLASS}
          isOpenRight={true}
          optionGroups={[
            [
              {
                key: 1,
                name: 'Contact Support',
                disabled: false,
                onClick: () => window.open(`mailto:${item.supportEmail}`, '_blank'),
              },
              {
                key: 2,
                name: 'Learn More',
                disabled: false,
                onClick: () => window.open(item.documentationUrl, '_blank'),
              },
            ],
          ]}
        />
      }
    />
  );
};

export default ConnectorsListItem;
