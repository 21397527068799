import React, { FunctionComponent, useState, useEffect } from 'react';
import { deleteAuthToken, deleteStoredEmail, deleteStoredPassword, getAuthToken } from '../../../helpers/auth';
import AuthService from '../../../services/authentication.service';
import { Redirect } from 'react-router';
import { FlightModal, FlightButton } from '@flybits/webapp-design-system-react';

const Clear: FunctionComponent<{}> = () => {
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const signOutUser = async () => {
    const authServiceManager = new AuthService();
    try {
      const signOutResponse = await authServiceManager.signOutUser();
      if (signOutResponse && signOutResponse.status === 204) {
        return true;
      } else return false;
    } catch (error) {
      if ((error && error.response && error.response.status >= 400) || error.response.status <= 599) {
        setError(true);
        setErrorMessage(error.message);
      }
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    const initiateCacheClear = async () => {
      const token = await getAuthToken();
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has('hash')) {
        const hash = queryParams.get('hash');
        if (hash) {
          const decodedHash = window.atob(hash);
          const decodedJson = JSON.parse(decodedHash);
          if (decodedJson && decodedJson['x-authorization']) {
            if (token) {
              const signOutSucess = await signOutUser();
              if (signOutSucess) {
                setRedirect(true);
              }
            }
            await deleteAuthToken();
          }
        }
      } else {
        if (token) {
          const signOutSucess = await signOutUser();
          if (signOutSucess) {
            setRedirect(true);
          }
        }
        await deleteAuthToken();
        await deleteStoredEmail();
        await deleteStoredPassword();
      }
    };
    initiateCacheClear();
  }, []);

  const toggleModal = () => {
    setError(false);
    setRedirect(true);
  };

  return (
    <div>
      <FlightModal
        isVisible={error}
        toggleModalShown={toggleModal}
        scrollable={false}
        size="small"
        warning={false}
        className="custom-modal-class"
        header={<span>Authentication Failed</span>}
        content={<span>{errorMessage}</span>}
        footer={
          <div className="modal-footer">
            <FlightButton type="primary" label="Back to sign in" onClick={toggleModal} />
          </div>
        }
      />
      {redirect && <Redirect to="/signin" />}
    </div>
  );
};

export default Clear;
