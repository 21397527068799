import React, { FunctionComponent, useState, useEffect } from 'react';
import { FlightButton } from '@flybits/webapp-design-system-react';
import projectEmptyState from '../assets/projects-empty-state.svg';

import '../styles/pages/Projects.scss';
import ProjectList from '../components/ProjectList/ProjectList';
import ProjectService from '../services/project.service';
import { getAuthToken } from '../helpers/auth';
import { Project, ProjectResponseBody } from '../model/projects/projects';
import { CreateProjectModal } from '../components/CreateProjectModal/CreateProjectModal';

const Projects: FunctionComponent<{}> = () => {
  const MAIN_CLASS = 'project';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const HEADER_CLASS = `${MAIN_CLASS}__header`;
  const SELECT_CLASS = `${MAIN_CLASS}__select`;
  const CREATE_PROJECT_CLASS = `${MAIN_CLASS}__create-project`;
  const DEV_PORTAL_CLASS = `${MAIN_CLASS}__devPortal`;
  const EMPTY_STATE_CLASS = `${MAIN_CLASS}__projects-empty-state`;
  const EMPTY_STATE_DESC_CLASS = `${MAIN_CLASS}__projects-empty-state-desc`;
  const LINK_CLASS = `${DEV_PORTAL_CLASS}__link`;
  const GRID_CLASS = `${MAIN_CLASS}__grid`;
  const PROJECT_LIST_CLASS = `${MAIN_CLASS}__projectList`;
  const [response, setResponse] = useState<Project[]>([]);
  const [devPortalUrl, setdevPortalUrl] = useState('');
  const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false);
  const [fetchProjects, setFetchedProjects] = useState(false);

  useEffect(() => {
    const fetchProjectsAndSignedInUser = async () => {
      const token = await getAuthToken();
      setdevPortalUrl(
        `${process.env.REACT_APP_API_DEV_PORTAL_URL}/#/authenticate?sourceRouteName=&token=${token}&userId=`,
      );
      const projectServiceManager = new ProjectService();
      try {
        const params = {
          limit: 1000000,
        };
        const projectResponseObject = await projectServiceManager.getProjects(params);
        if (projectResponseObject) {
          const transformedResponse: Project[] = [];
          projectResponseObject.data.data.map((project: ProjectResponseBody) => {
            return transformedResponse.push({
              id: project.id,
              name: project.Name,
              subDomain: project.Subdomain,
              creatorID: project.CreatorID,
              isActive: project.IsActive,
              tierID: project.TierID,
              level: project.level,
            });
          });
          setResponse(transformedResponse);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProjectsAndSignedInUser();
  }, [fetchProjects]);

  const toggleModal = () => {
    setOpenCreateProjectModal(!openCreateProjectModal);
  };

  const goToDevPortal = () => {
    window.location.href = devPortalUrl;
  };

  const triggerSetFetchProjects = () => {
    setFetchedProjects(!fetchProjects);
  };

  return (
    <div className={MAIN_CLASS}>
      
      <CreateProjectModal
        openModal={openCreateProjectModal}
        toggleModal={toggleModal}
        fetchProjects={triggerSetFetchProjects}
      />
      <div className={CONTENT_CLASS}>
        <div className={GRID_CLASS}>
          <div className={HEADER_CLASS}>
            <div className={SELECT_CLASS}>Select a Project</div>
            <FlightButton
              className={CREATE_PROJECT_CLASS}
              label="Create New Project"
              size="medium"
              type="secondary"
              onClick={toggleModal}
            />
          </div>
          <div className={DEV_PORTAL_CLASS}>
            <FlightButton
              label="Go to Developer Portal"
              size="large"
              className={LINK_CLASS}
              theme="link"
              iconRight="baselineArrowRight"
              onClick={goToDevPortal}
            />
          </div>
          <div className={PROJECT_LIST_CLASS}>
            {response.length ? (
              <ProjectList list={response} />
            ) : (
              <div className={EMPTY_STATE_CLASS}>
                <img src={projectEmptyState} alt="There are no projects." />
                <p className={EMPTY_STATE_DESC_CLASS}>
                  You currently don’t have any projects. Create your first one by clicking the
                  <FlightButton label="Create New Project" size="large" type="link" onClick={toggleModal} />
                  button!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
