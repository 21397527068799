import { Categories } from '../model/projectCustomSettings/projectCustomSettings';

export const categories: Categories[] = [
  {
    displayName: 'Starter Templates',
    labels: ['starter'],
  },
  {
    displayName: 'My Templates',
    labels: [],
  },
];
