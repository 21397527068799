import React, { FunctionComponent } from 'react';
import './BannerImage.scss';
import { getIcon } from '@flybits/webapp-design-system-react';
import { BANNER_IMAGE_DESCRIPTION } from '../../constants/descriptions';

const BannerImage: FunctionComponent<{}> = () => {
  const MAIN_CLASS = 'BannerImage';
  const WRAPPER_CLASS = `${MAIN_CLASS}__wrapper`;
  const COVER_CLASS = `${MAIN_CLASS}__cover`;
  const CONTAINER_CLASS = `${MAIN_CLASS}__container`;
  const HEADER_CLASS = `${CONTAINER_CLASS}__header`;
  const DESCRIPTION_CLASS = `${CONTAINER_CLASS}__description`;
  const REDIRECT_CLASS = `${CONTAINER_CLASS}__redirect`;
  const ICON_CLASS = {
    className: `${REDIRECT_CLASS}__icon`,
  };

  return (
    <div className={WRAPPER_CLASS}>
      <svg viewBox="0 0 100 100" preserveAspectRatio="none" className={COVER_CLASS}>
        <path id="start" d="M 0 100 V 100 Q 50 100 100 100 V 100 z" fill="#0851df" />
      </svg>
      <div className={MAIN_CLASS}>
        <div className={CONTAINER_CLASS}>
          <div className={HEADER_CLASS}>Digital Experience Platform</div>
          <div className={DESCRIPTION_CLASS}>{BANNER_IMAGE_DESCRIPTION}</div>
          <div className={REDIRECT_CLASS}>
            <a
              className={`${REDIRECT_CLASS}__link`}
              href="https://www.flybits.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Learn more {getIcon('baselineArrowRight', ICON_CLASS)}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerImage;
