import React, { FunctionComponent, useState, useEffect } from 'react';
import SvgFlybitsLogo from '../assets/FlybitsLogo';
import BannerImage from '../components/BannerImage/BannerImage';
import {
  FlightButton,
  FlightTextInput,
  FlightCheckbox,
  getIcon,
  FlightSnackbar,
  FlightModal,
} from '@flybits/webapp-design-system-react';
import { Link } from 'react-router-dom';
import '../styles/pages/SignUp.scss';
import {
  PASSWORD_VALIDATION,
  EMAIL_VALIDATION,
  LAST_NAME_VALIDATION,
  LAST_NAME_REQUIRED,
  FIRST_NAME_VALIDATION,
  FIRST_NAME_REQUIRED,
  PASSWORD_REQUIRED,
  EMAIL_REQUIRED,
  USER_TERMS_OF_SERVICE_REQUIRED,
  GENERIC_INTERNAL_ERROR,
  CONFIRMATION_PASSWORD_VALIDATION,
  EMAIL_TAKEN_ERROR,
} from '../constants/errors';
import { setAuthToken, passwordRegex, nameRegex } from '../helpers/auth';
import AuthService from '../services/authentication.service';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import { gsap } from 'gsap';

const SignUp: FunctionComponent<{}> = () => {
  useEffect(() => {
    gsap.timeline().fromTo(
      `.signup__form > *`,
      { y: -20, opacity: 0 },
      {
        y: 0,
        duration: 0.8,
        stagger: 0.125,
        opacity: 1,
      },
      '+=.4',
    );
  }, []);

  const MAIN_CLASS = 'signup';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const LINK_CLASS = `${ITEM_CLASS}__link`;
  const REDIRECT_CLASS = `${LINK_CLASS}__redirect`;
  const MODAL_CLASS = `${MAIN_CLASS}__modal`;
  const ICON_CLASS = {
    className: `${REDIRECT_CLASS}__icon`,
  };
  interface FormValues {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    confirmPassword: string;
    agreement: boolean;
  }
  const initialValues = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    confirmPassword: '',
    agreement: false,
  };

  const [email, setEmail] = useState('');
  const [emailTakenError, setIsEmailTakenError] = useState(false);
  const [signUpSuccessful, setSignUpSuccessful] = useState(false);
  const [confirmEmailSuccessful, setConfirmEmailSuccessful] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(EMAIL_REQUIRED).email(EMAIL_VALIDATION),
    password: Yup.string().required(PASSWORD_REQUIRED).matches(passwordRegex, PASSWORD_VALIDATION),
    confirmPassword: Yup.string()
      .required(PASSWORD_REQUIRED)
      .matches(passwordRegex, PASSWORD_VALIDATION)
      .test('match', CONFIRMATION_PASSWORD_VALIDATION, function (confirmPassword) {
        return confirmPassword === this.parent.password;
      }),
    firstName: Yup.string().required(FIRST_NAME_REQUIRED).matches(nameRegex, FIRST_NAME_VALIDATION),
    lastName: Yup.string().required(LAST_NAME_REQUIRED).matches(nameRegex, LAST_NAME_VALIDATION),
    agreement: Yup.boolean().required(USER_TERMS_OF_SERVICE_REQUIRED).oneOf([true], USER_TERMS_OF_SERVICE_REQUIRED),
  });

  const initSignUp = async (values: FormValues) => {
    const signUpRequestObject = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      isAgreedToTermsAndPrivacy: values.agreement,
    };
    setLoading(true);
    const authServiceManager = new AuthService();
    try {
      const response = await authServiceManager.signUpUser(signUpRequestObject);
      if (response && response.status === 200) {
        setSignUpSuccessful(true);
        setLoading(false);
        if (response.headers && response.headers['x-authorization']) {
          setAuthToken(response.headers['x-authorization']);
          setEmail(values.email);
          return true;
        }
      }
    } catch (error) {
      if (error?.response?.data?.error?.exceptionMessage) {
        if (error.response.data.error.exceptionMessage.indexOf('already taken') > 0) {
          setIsEmailTakenError(true);
        } else {
          setErrorMessage(error.response.data.error.exceptionMessage);
          setError(true);
        }
      } else {
        setErrorMessage(GENERIC_INTERNAL_ERROR);
        setError(true);
      }
      console.error(error);
      setLoading(false);
      return false;
    }
  };

  const sendEmailVerification = async () => {
    const authServiceManager = new AuthService();
    const sendConfirmEmailRequestBody = {
      email: email,
      projectId: '00000000-0000-0000-0000-000000000000',
    };
    try {
      const response = await authServiceManager.sendConfirmationEmail(sendConfirmEmailRequestBody);
      if (response && response.status === 204) {
        setConfirmEmailSuccessful(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleConfirmEmailSuccessful = () => {
    setConfirmEmailSuccessful(false);
  };

  const toggleModal = () => {
    setError(false);
  };

  return (
    <div className={MAIN_CLASS}>
      <FlightModal
        isVisible={error}
        toggleModalShown={toggleModal}
        scrollable={false}
        size="small"
        warning={false}
        className={MODAL_CLASS}
        header={<span>Authentication Failed</span>}
        content={<span>{errorMessage}</span>}
        footer={
          <div className={`${MODAL_CLASS}__footer`}>
            <FlightButton type="primary" label="Back to sign in" onClick={toggleModal} />
          </div>
        }
      />
      <BannerImage />
      {signUpSuccessful && (
        <div className={`${ITEM_CLASS}__signup-confirmation`}>
          <FlightSnackbar
            className={`${MAIN_CLASS}__reset-snackbar`}
            isFloating={false}
            isVisible={confirmEmailSuccessful}
            content="Email sent."
            type="success"
            isAutoDismiss={false}
            actionName=""
            handleClose={toggleConfirmEmailSuccessful}
          />
          <div className={`${MAIN_CLASS}__confirmation-text`}>
            Thank you for signing up for your Flybits account. You will receive a verification email shortly.
          </div>
          <FlightButton
            className={`${ITEM_CLASS}__button`}
            type="primary"
            size="large"
            label="Resend verification email"
            onClick={sendEmailVerification}
          />
          <div className={`${ITEM_CLASS}__signin`}>
            <Link to="signin" className={`${LINK_CLASS}-confirmation-redirect`}>
              Go to sign in&nbsp;{getIcon('baselineArrowRight', ICON_CLASS)}
            </Link>
          </div>
        </div>
      )}
      {!signUpSuccessful && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            const result = await initSignUp(values);
            if (result) {
              resetForm();
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
            <form className={FORM_CLASS}>
              <SvgFlybitsLogo width={104} height={30} viewBox="0 0 104 30" {...{ className: `${MAIN_CLASS}__logo` }} />
              <div className={`${MAIN_CLASS}__heading-text`}>Sign up</div>
              <div className={ITEM_CLASS}>
                <Field
                  type="text"
                  name="firstName"
                  className={`${ITEM_CLASS}__input ${ITEM_CLASS}__input__inline`}
                  width="100%"
                  as={FlightTextInput}
                  hasError={touched.firstName && errors.firstName ? true : false}
                  label="First Name"
                  value={values.firstName}
                  errorMessage={<span>{errors.firstName}</span>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Field
                  type="text"
                  name="lastName"
                  className={`${ITEM_CLASS}__input ${ITEM_CLASS}__input__inline`}
                  width="100%"
                  as={FlightTextInput}
                  hasError={touched.lastName && errors.lastName ? true : false}
                  label="Last Name"
                  value={values.lastName}
                  errorMessage={<span>{errors.lastName}</span>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={ITEM_CLASS}>
                <Field
                  type="text"
                  name="email"
                  className={`${ITEM_CLASS}__input ${ITEM_CLASS}__input__inline`}
                  width="100%"
                  as={FlightTextInput}
                  hasError={(touched.email && errors.email) || emailTakenError ? true : false}
                  label="Email"
                  value={values.email}
                  errorMessage={<span>{emailTakenError ? EMAIL_TAKEN_ERROR : errors.email}</span>}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (emailTakenError) {
                      setIsEmailTakenError(false);
                    }
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div className={ITEM_CLASS}>
                <Field
                  type="password"
                  name="password"
                  className={`${ITEM_CLASS}__input ${ITEM_CLASS}__input__inline`}
                  width="100%"
                  as={FlightTextInput}
                  hasError={touched.password && errors.password ? true : false}
                  label="Password"
                  value={values.password}
                  errorMessage={<span>{errors.password}</span>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={ITEM_CLASS}>
                <Field
                  type="password"
                  name="confirmPassword"
                  className={`${ITEM_CLASS}__input ${ITEM_CLASS}__input__inline`}
                  width="100%"
                  as={FlightTextInput}
                  hasError={touched.confirmPassword && errors.confirmPassword ? true : false}
                  label="Confirm Password"
                  value={values.confirmPassword}
                  errorMessage={<span>{errors.confirmPassword}</span>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={`${ITEM_CLASS}__row`}>
                <div className={`${ITEM_CLASS}__checkbox`}>
                  <div className={`${ITEM_CLASS}__checkbox-container`}>
                    <Field
                      as={FlightCheckbox}
                      name="agreement"
                      checkState={values.agreement ? 'SELECTED' : 'UNSELECTED'}
                      hasError={touched.agreement && values.agreement ? false : false}
                      errorMessage={<span>{errors.agreement}</span>}
                      value={values.agreement}
                      onSelect={() => {
                        setFieldValue('agreement', !values.agreement, false);
                        setFieldTouched('agreement', true);
                      }}
                    />
                  </div>
                  <div className={`${ITEM_CLASS}__agreement`}>
                    By signing up, you indicate that you agree to the
                    <a
                      href="https://www.flybits.com/legal/terms-of-use/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={LINK_CLASS}
                    >
                      {' '}
                      Terms of Service{' '}
                    </a>{' '}
                    and
                    <a
                      href="https://www.flybits.com/legal/terms-of-use/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={LINK_CLASS}
                    >
                      {' '}
                      Privacy Policy
                    </a>
                    .
                  </div>
                </div>
                {touched.agreement && !values.agreement && (
                  <div className={`${ITEM_CLASS}__checkbox-error-container`}>*{USER_TERMS_OF_SERVICE_REQUIRED}</div>
                )}
              </div>
              <FlightButton
                className={`${ITEM_CLASS}__button`}
                type="primary"
                size="large"
                label="Sign up"
                loading={isLoading}
                disabled={
                  isLoading ||
                  !touched.firstName ||
                  !!errors.firstName ||
                  !touched.lastName ||
                  !!errors.lastName ||
                  !touched.email ||
                  !!errors.email ||
                  !touched.agreement ||
                  !!errors.agreement ||
                  !touched.password ||
                  !!errors.password ||
                  !touched.confirmPassword ||
                  !!errors.confirmPassword
                }
                onClick={handleSubmit}
              />
              <div className={`${ITEM_CLASS}__signin`}>
                Already have a Flybits account?&nbsp;
                <Link to="signin" className={LINK_CLASS}>
                  Sign In
                </Link>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default SignUp;
