import React, { useEffect, useCallback, useRef } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { FlightTextInput } from '@flybits/webapp-design-system-react';
import { ReactComponent as ClearIcon } from '../../../assets/icons/clear-icon.svg';
import './DoubleArrayFields.scss';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (data: any, nameData: any) => void;
  initialValues: { values: Array<string>; names: Array<string> };
  placeHolderText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preDefinedOptions?: any[];
  secondPlaceHolderText?: string;
  validationSchema?: {};
  removeListener?: boolean;
  hasError: (data: boolean) => void;
}

interface ArrayForm {
  values: Array<string>;
  names: Array<string>;
}
export const DoubleArrayFields = (props: Props) => {
  const {
    callback,
    removeListener,
    initialValues,
    preDefinedOptions,
    placeHolderText,
    secondPlaceHolderText,
    validationSchema,
  } = props;
  const onFormSubmit = (values: ArrayForm) => {
    const removeStrays = values.values.filter(function (str: string) {
      return /\S/.test(str);
    });
    const removeStraysNames = values.names.filter(function (str: string) {
      return /\S/.test(str);
    });
    callback(removeStrays, removeStraysNames);
  };
  function setUpdatedValue(values: ArrayForm) {
    onFormSubmit(values);
  }
  // eslint-disable-next-line
  const inputRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const secondInputRef = useRef<any>();
  if (preDefinedOptions?.length !== 0 && secondInputRef.current) {
    secondInputRef.current.onClick();
  }
  function handleDelete(values: ArrayForm, index: number) {
    values.values.splice(index, 1);
    values.names.splice(index, 1);
    setUpdatedValue(values);
  }

  const triggerAdd = () => {
    if (inputRef?.current) {
      inputRef?.current?.onclick();
    }
  };

  const keyHandler = useCallback((event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      triggerAdd();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!removeListener) {
      document.addEventListener('keydown', keyHandler, false);
    }
    return () => {
      document.removeEventListener('keydown', keyHandler, false);
    };
  });

  return (
    <div className="array-fields">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async () => onFormSubmit}
      >
        {({ values, errors, handleChange, handleBlur, setFieldError, touched, setFieldValue, resetForm }) => (
          <>
            <Form onBlur={() => setUpdatedValue(values)}>
              <div className="array-fields__header">
                <span className="array-fields__header__values">Values</span>
                <span className="array-fields__header__display-names">Display Names</span>
              </div>
              <FieldArray
                name="values"
                render={(arrayHelpers) => (
                  <>
                    <button
                      className="array-fields__btn-no-display"
                      ref={inputRef}
                      onClick={() => (errors.values ? null : arrayHelpers.push(''))}
                    />
                    {values.values && values.values.length > 0 ? (
                      values.values.map((field, index) => (
                        <div key={index} className="array-fields__container">
                          <ClearIcon
                            className="flight-text-input__input__double_clear-icon"
                            onClick={() => {
                              if (index === 0 && values.values.length === 1) {
                                if (values.values[0] === '') {
                                  return;
                                } else {
                                  arrayHelpers.handleRemove(index);
                                  handleDelete(values, 0);
                                  arrayHelpers.push('');
                                }
                              } else {
                                setFieldError(`values.${index}`, '');
                                arrayHelpers.handleRemove(index);
                                handleDelete(values, index);
                              }
                            }}
                          />

                          <div className="array-fields__index">{index + 1}</div>
                          <div className="array-fields__text-field__values">
                            <Field
                              as={FlightTextInput}
                              name={`values.${index}`}
                              id={`values.${index}`}
                              className="array-fields__input__values"
                              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                handleChange(e);
                                if (!touched.names) {
                                  setFieldValue(`names.${index}`, e.currentTarget.value);
                                } else if (Array.isArray(touched.names) && !touched.names?.[index]) {
                                  setFieldValue(`names.${index}`, e.currentTarget.value);
                                }
                              }}
                              hasError={
                                Array.isArray(touched.names) && touched.names?.[index] && !values?.names?.[index]
                                  ? true
                                  : false
                              }
                              placeholderText={placeHolderText || ''}
                              width="230px"
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="array-fields__text-field__display-names">
                            <Field
                              as={FlightTextInput}
                              className="array-fields__input__display-names"
                              name={`names.${index}`}
                              id={`names.${index}`}
                              onChange={handleChange}
                              placeholderText={secondPlaceHolderText || ''}
                              width="230px"
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <button type="button" ref={inputRef} onClick={() => arrayHelpers.push('')}>
                        Add Field
                      </button>
                    )}
                  </>
                )}
              />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default DoubleArrayFields;
