import React, { FunctionComponent } from 'react';
import { FlightCard, getIcon } from '@flybits/webapp-design-system-react';
import './CtxAttributeCard.scss';

interface IProps {
  name: string;
  selected: string;
  uid: string;
  tableRowIdx?: number;
  tableCellIdx?: number;
  removeCtxAttribute?: (...args:any[]) => void;
}

const CtxAttributeCard: FunctionComponent<IProps> = (props) => {
  const { name, selected, uid, tableRowIdx, tableCellIdx, removeCtxAttribute} = props;
  const MAIN_CLASS = 'Ctx-attribute-card';
  const CARD_CONTAINER_CLASS = `${MAIN_CLASS}__card-container`;
  const NAME_CLASS = `${MAIN_CLASS}__name`;
  const UID_CLASS = `${MAIN_CLASS}__uid`;
  const REMOVE_BTN_CLASS =  `${MAIN_CLASS}__remove`;

  const ICON_CLASS = {
    className: `${REMOVE_BTN_CLASS}__icon`,
  };

  return (
    <div className={MAIN_CLASS}>
      <FlightCard
        className={CARD_CONTAINER_CLASS}
        header=""
        body={
          <div>
            <p className={NAME_CLASS}>{name}</p>
            <p className={UID_CLASS}>{uid}</p>
          </div>
        }
        actionables={
          <>{ removeCtxAttribute ?
              <button
                type="button"
                className={REMOVE_BTN_CLASS}
                onClick={(e) => {
              removeCtxAttribute(name, selected, tableRowIdx, tableCellIdx);
            }}>{getIcon('remove', ICON_CLASS)}</button>  : <></> }
          </>
         }
        footer=""
      />
    </div>
  );
};

export default CtxAttributeCard;