import React, { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import ConnectorService from '../../services/connectors.service';
import DatasourceService from '../../services/datasources.service';
import { FlightModal, FlightButton } from '@flybits/webapp-design-system-react';
import { Connector, ConnectorResponseBody } from '../../model/connectors/connectors';
import ConnectorsList from '../../components/ConnectorsList/ConnectorsList';
import { getIcon } from '@flybits/webapp-design-system-react';
import '../../styles/pages/Datasources/create.scss';

type errorMessage = {
  header: string,
  content: string,
};

const DatasourcesCreationPage: FunctionComponent<{}> = () => {
  const MAIN_CLASS = 'datasources-creation';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const HEADER_CLASS = `${MAIN_CLASS}__header`;
  const GRID_CLASS = `${MAIN_CLASS}__grid`;
  const SEARCH_CLASS = `${HEADER_CLASS}__search`;
  const TEXT_CLASS = `${HEADER_CLASS}__text`;
  const TITLE_CLASS = `${TEXT_CLASS}__title`;
  const SUBTITLE_CLASS = `${TEXT_CLASS}__subtitle`;
  const EMPTY_CLASS = `${MAIN_CLASS}__empty`;
  const DATASOURCE_LIST_CLASS = `${MAIN_CLASS}__datasourceList`;
  const ICON_CLASS = {
    className: `${MAIN_CLASS}__icon`,
  };

  const [connectorsList, setConnectorsList] = useState<Connector[]>([]);
  const [errorMessage, setErrorMessage] = useState<errorMessage>({ header: '', content: ''});
  const [isShowErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const fetchConnectors = async (value?: string) => {
    const connectorServiceManager = new ConnectorService();
    setIsLoading(true);
    setShowErrorModal(false);

    try {
      const params = {
        type: 'available',
        limit: 100,
        sort: 'name+',
        search: value ? value : '',
      };
      const connectoResponseObject = await connectorServiceManager.getConnectors(params);
      const transformedConnectorsList: Connector[] = [];
      connectoResponseObject.data.data.map((connector: ConnectorResponseBody) => {
        return transformedConnectorsList.push({
          id: connector.id,
          name: connector.name,
          description: connector.description,
          imageUrl: connector.imageUrl,
          updatedAt: connector.updatedAt,
          status: connector.status,
          providerName: connector.providerName,
          documentationUrl: connector.documentationUrl,
          supportEmail: connector.supportEmail,
          configTemplate: connector.configTemplate,
        });
      });

      setConnectorsList(transformedConnectorsList);
      setIsLoading(false);      
    } catch (error) {
      setIsLoading(false);
      setShowErrorModal(true);
      setErrorMessage({ ...errorMessage,
        header:  'Error',
        content: error.message || `There was an error loading the connectors.`,
      });
    }
  };

  useEffect(() => {
    fetchConnectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectToDatasourceSharingPage = async (connectorId: string) => {
    const datasourceName = `Untitled-Datasource-${moment().format('DD-MM-YY-HH-MM-SS')}`;
    const datasource = {
      connectorId: connectorId,
      name: datasourceName,
      status: 'created',
    };
    setShowErrorModal(false);
    try {
      const datasourceServiceManager = new DatasourceService();
      const newDataSourceResponse = await datasourceServiceManager.createDatasource(datasource);

      const connector = connectorsList.filter((connector) => connector.id === connectorId)[0];
      const location = {
        pathname: `/datasources/create/${newDataSourceResponse.data.id}`,
        state: {
          connectorId,
          connector,
        },
      };
      history.push(location);
    } catch (error) {
      setShowErrorModal(true);
      setErrorMessage({ ...errorMessage,
        header:  'Error',
        content: error.message || `There was an error.`,
      });
    }
  };

  return (
    <div className={MAIN_CLASS}>
      <div className={CONTENT_CLASS}>
        <FlightModal
          isVisible={isShowErrorModal}
          toggleModalShown={() => { setShowErrorModal(false); }}
          scrollable={false}
          size="small"
          warning={true}
          className="custom-modal-class"
          header={errorMessage.header}
          content={errorMessage.content}
          footer={<div className="modal-footer">
            <FlightButton theme="primary" label="Got it!" onClick={() => { setShowErrorModal(false); }}/>
            </div>}
          />
        <div className={GRID_CLASS}>
          <div className={HEADER_CLASS}>
            <div className={TEXT_CLASS}>
              <div className={TITLE_CLASS}>Connect to your data</div>
              <div className={SUBTITLE_CLASS}>Choose a connection method that matches the data you want to access.</div>
            </div>
            <div className={SEARCH_CLASS}>
              <SearchBar searchText={(e) => fetchConnectors(e)} />
            </div>
          </div>
          <div className={DATASOURCE_LIST_CLASS}>
            { isLoading ? <div className={`${MAIN_CLASS}__loading`}>{getIcon('loading', ICON_CLASS)}</div> : <></>}
            {(connectorsList.length === 0 && !isLoading) && <div className={EMPTY_CLASS}>No results found.</div>}
            {(connectorsList.length > 0 && !isLoading) && (
              <ConnectorsList
                handleRedirectToDatasourceSharingPage={handleRedirectToDatasourceSharingPage}
                list={connectorsList}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DatasourcesCreationPage;
