import React, { FunctionComponent } from 'react';
import DatasourcesListItem from '../DatasourcesListItem/DatasourcesListItem';
import { Datasource } from '../../model/datasources/datasources';
import './DatasourcesList.scss';

interface Props {
  list: Array<Datasource>;
}

const DatasourcesList: FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'datasources-list';
  const WRAPPER_CLASS = `${MAIN_CLASS}__wrapper`;
  const { list } = props;
  return (
    <div className={MAIN_CLASS}>
      <div className={WRAPPER_CLASS}>
        {list.map((item: Datasource) => (
          <DatasourcesListItem item={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default DatasourcesList;
