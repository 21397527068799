import './Sidepanel.scss';
import React, { useEffect } from 'react';
interface Props {
  isVisible: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  toggleClose: () => void;
}
export const Sidepanel = (props: Props) => {
  const { children, isVisible } = props;

  const [xPosition, setX] = React.useState(isVisible ? 235 : 1500);
  useEffect(() => {
    // eslint-disable-next-line
    setX(isVisible ? 235 : 1500);
  }, [isVisible]);

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add(`flight-side-panel-wrapper--scroll-lock`);
    }
    return () => {
      document.body.classList.remove(`flight-side-panel-wrapper--scroll-lock`);
    };
    // eslint-disable-next-line
  }, [isVisible]);

  if (!isVisible) return null;
  return (
    <React.Fragment>
      <div className="flight-side-panel-wrapper">
        <div className={xPosition < 1500 ? 'flight-side-panel-wrapper__overlay' : ''}></div>
        <div className="flight-side-panel-main">
          <div
            className="flight-side-panel-main__side-panel"
            style={{
              transform: `translatex(${xPosition}px)`,
              width: '60vw',
            }}
          >
            <div>{children}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
