import React, { FunctionComponent, useState, useEffect } from 'react';
import ProjectListItem from './ProjectListItem';
import { Project } from '../../model/projects/projects';
import bulletTrain from 'bullet-train-client';
import { APPLICATIONS_PAGE_ENABLED } from '../../constants/featureFlags';
import './ProjectList.scss';

interface Props {
  list: Array<Project>;
}

const ProjectList: FunctionComponent<Props> = (props: Props) => {
  const { list } = props;
  const DEFAULT_CLASS = 'projectList';
  const WRAPPER_CLASS = `${DEFAULT_CLASS}__wrapper`;
  const [redirectExperienceStudio, setRedirectExperienceStudio] = useState(false);

  useEffect(() => {
    bulletTrain.init({
      environmentID: `${process.env.REACT_APP_BULLET_TRAIN_ENVIRONMENT_ID}`,
      onChange: () => {
        if (!bulletTrain.hasFeature(APPLICATIONS_PAGE_ENABLED)) {
          setRedirectExperienceStudio(true);
        }
      },
    });
  }, []);

  return (
    <div className={WRAPPER_CLASS}>
      {list.map((item: Project) => (
        <ProjectListItem
          key={item.id}
          id={item.id}
          name={item.name}
          url={item.subDomain}
          isActive={item.isActive}
          redirectExperienceStudio = {redirectExperienceStudio}
          />
      ))}
    </div>
  );
};

export default ProjectList;
