import React, { FunctionComponent } from 'react';
import OrganizationListItem from './OrganizationListItem';
import { Organization } from '../../model/organizations/organizations';
import './OrganizationList.scss';

interface Props {
  list: Array<Organization>;
}

const OrganizationList: FunctionComponent<Props> = (props: Props) => {
  const { list } = props;
  const DEFAULT_CLASS = 'organizationList';
  const WRAPPER_CLASS = `${DEFAULT_CLASS}__wrapper`;

  return (
    <div className={WRAPPER_CLASS}>
      {list.map((item: Organization) => (
        <OrganizationListItem
          key={item.id}
          id={item.id}
          name={item.name}
          logo={item.logo}
          isActive={item.isActive}
          isVerified={item.isVerified}
        />
      ))}
    </div>
  );
};

export default OrganizationList;
