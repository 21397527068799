import React, { FunctionComponent } from 'react';
import { Datasource } from '../../model/datasources/datasources';
import { getIcon } from '@flybits/webapp-design-system-react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import './DatasourcesListItem.scss';
interface Props {
  item: Datasource;
}

const DatasourcesListItem: FunctionComponent<Props> = (props: Props) => {
  const { item } = props;
  const MAIN_CLASS = 'datasources-list-item';
  const LEFT_SIDE_CLASS = `${MAIN_CLASS}__left`;
  const IMAGE_CLASS = `${LEFT_SIDE_CLASS}__image`;
  const TEXT_CLASS = `${LEFT_SIDE_CLASS}__text`;
  const TITLE_CLASS = `${TEXT_CLASS}__title`;
  const SUBTITLE_CLASS = `${TEXT_CLASS}__subtitle`;
  const RIGHT_SIDE_CLASS = `${MAIN_CLASS}__right`;
  const LAST_MODIFIED_CLASS = `${TEXT_CLASS}__last-modified`;
  const REVOKED_CLASS = `${TEXT_CLASS}__revoked`;
  const history = useHistory();
  const formattedDate = moment.unix(item.updatedAt).format('YYYY/MM/D');
  const redirectToEdit = () => {
    const location = {
      pathname: `/datasources/edit/${item.id}`,
    };
    history.push(location);
  };
  return (
    <div className={MAIN_CLASS}>
      <div className={LEFT_SIDE_CLASS}>
        {item.imageUrl && (
          <div className={IMAGE_CLASS}>
            {' '}
            <img src={item.imageUrl} alt="datasource thumbnail"></img>
          </div>
        )}
        <div className={TEXT_CLASS}>
          <div className={TITLE_CLASS} onClick={redirectToEdit}>{item.name}</div>
          <div className={SUBTITLE_CLASS}>Shared with {item.tenantViewScope.length} projects</div>
        </div>
      </div>
      <div className={RIGHT_SIDE_CLASS}>
        <div className={LAST_MODIFIED_CLASS}>Last modified: {formattedDate}</div>
        {item.status === 'revoked' ||
          (item.status === 'failed' && (
            <div className={REVOKED_CLASS}>{getIcon('warning', null)} Token Revoked, reauthorization required.</div>
          ))}
      </div>
    </div>
  );
};

export default DatasourcesListItem;
