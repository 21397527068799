const BANNER_IMAGE_DESCRIPTION =
  'Our unrivaled contextual recommendation engine lets your financial institution deliver hyper-personalized products, offers, and content your customers actually need.';
const BASIC_INFO_SECTION_DESCRIPTION = 'This is the information others will see when deciding to use this datasource.';
const BASIC_INFO_NAME_DESCRIPTION = `This name will appear on shared project's datasources list, and the generated context plugin.`;
const BASIC_INFO_PROVIDER_NAME_DESCRIPTION = `This name will appear on shared project's datasources list.`;
const BASIC_INFO_IMAGE_URL_DESCRIPTION = 'A square logo that will be used to identify your datasource.';
const SUPPORT_INFO_SECTION_DESCRIPTION = `Users can find this info once they've added your connector instace to their project. Documentation URLs and Support URLs are required. Learn more`;


export {
  BANNER_IMAGE_DESCRIPTION,
  BASIC_INFO_SECTION_DESCRIPTION,
  BASIC_INFO_NAME_DESCRIPTION,
  BASIC_INFO_PROVIDER_NAME_DESCRIPTION,
  SUPPORT_INFO_SECTION_DESCRIPTION,
  BASIC_INFO_IMAGE_URL_DESCRIPTION,
};
