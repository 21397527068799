export const dataFormatOptions = [
  {
    key: 'string',
    name: 'String',
  },
  {
    key: 'int',
    name: 'Integer',
  },
  {
    key: 'float',
    name: 'Float',
  },
  {
    key: 'bool',
    name: 'Boolean',
  },
  {
    key: 'dateTime',
    name: 'Date Time',
  },
];
