import * as Yup from 'yup';
import {
  NAME_REQUIRED,
  NO_SPECIAL_CHARACTERS,
  GENERIC_REQUIRED,
  MUST_BE_INT,
  MUST_BE_FLOAT,
  MUST_BE_BOOLEAN,
} from './errors';

export const nameRegex = /^[A-Za-z0-9-]*$/;
export const validationSchema = Yup.object().shape({
  name: Yup.string().required(NAME_REQUIRED).matches(nameRegex, NO_SPECIAL_CHARACTERS),
});

export const integerRegex = /^\+?\d+$/;
export const validateInteger = Yup.object().shape({
  values: Yup.array().of(Yup.string().required(GENERIC_REQUIRED).matches(integerRegex, MUST_BE_INT)),
  key: Yup.string().required(GENERIC_REQUIRED),
});

export const validateString = Yup.object().shape({
  values: Yup.array().of(Yup.string().required(GENERIC_REQUIRED)),
  key: Yup.string().required(GENERIC_REQUIRED),
});

export const floatRegex = /^[+-]?\d+(\.\d+)?$/;
export const validateFloat = Yup.object().shape({
  values: Yup.array().of(Yup.string().required(GENERIC_REQUIRED).matches(floatRegex, MUST_BE_FLOAT)),
  key: Yup.string().required(GENERIC_REQUIRED),
});

export const booleanRegex = /^(true|false)$/;
export const validateBoolean = Yup.object().shape({
  values: Yup.array().of(Yup.string().required(GENERIC_REQUIRED).matches(booleanRegex, MUST_BE_BOOLEAN)),
  key: Yup.string().required(GENERIC_REQUIRED),
});
