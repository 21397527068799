import React from 'react';

//Create Control Tower Routes Context

const pathname = window.location.pathname;
const isControlTower = pathname.startsWith('/ct');

export const ControlTowerContext = React.createContext<boolean>(isControlTower);

type Props = {
  children: React.ReactNode;
};
export const ControlTowerProvider = ({ children }: Props) => {
  const [controlTower, setControlTower] = React.useState(isControlTower);

  React.useEffect(() => {
    setControlTower(window.location.pathname.startsWith('/ct'));
  }, []);

  return <ControlTowerContext.Provider value={controlTower}>{children}</ControlTowerContext.Provider>;
};

export const useControlTowerContext = () => React.useContext(ControlTowerContext);
