import BaseService from './base.service';
import { DefaultDatasourceCreationParameters, Datasource } from '../model/datasources/datasources';

interface GetDatasources {
  limit?: number;
  sort?: string;
  search?: string;
  id?: string;
}

interface DatasourceAuthorizationParameters {
  clientId: string;
  clientSecret: string;
  connectorInstanceId: string;
  authTag?: string;
}

interface DatasourceDisconnectParams {
  connectorInstanceId: string;
  authTag: string;
}

export default class DatasourcesService extends BaseService {
  CURRENT_PATH: string;
  OAUTH_MANAGER_PATH: string;
  OAUTH_DISCONNECT_PATH: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'context/connector-manager/connectorInstance';
    this.OAUTH_MANAGER_PATH = 'sso/oauth-manager/authorize';
    this.OAUTH_DISCONNECT_PATH = '/sso/oauth-manager/clientInfo';
  }

  async getDatasources(params: GetDatasources) {
    return this.request('GET', this.CURRENT_PATH, params);
  }

  async checkConnectorInstanceName(name: string) {
    return this.request('GET', `${this.CURRENT_PATH}/checkName`, { name: name });
  }

  async createDatasource(params: DefaultDatasourceCreationParameters) {
    return this.request('POST', this.CURRENT_PATH, params);
  }

  async authorizeDatasource(params: DatasourceAuthorizationParameters) {
    return this.request('POST', this.OAUTH_MANAGER_PATH, params);
  }

  async disconnectDatasource(params: DatasourceDisconnectParams) {
    return this.request('DELETE', this.OAUTH_DISCONNECT_PATH, params);
  }

  async updateDatasource(params: Datasource) {
    return this.request('PUT', this.CURRENT_PATH, params);
  }

  async deleteDatasource(datasourceId: string) {
    return this.request('DELETE', `${this.CURRENT_PATH}/${datasourceId}`);
  }
}
