import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ControlTowerRoutes from './ControlTower';
import SSORoutes from './SSO';
import { useControlTowerContext } from '../contexts/ControlTowerContext';

const Routes = () => {
  const isControlTower = useControlTowerContext();

  const RouteToLoad = isControlTower ? ControlTowerRoutes : SSORoutes;
  return (
    <Router basename={isControlTower ? '/ct' : '/'}>
      <RouteToLoad />
    </Router>
  );
};

export default Routes;
