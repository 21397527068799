import React, { FunctionComponent, useState, useEffect } from 'react';
import { FlightButton } from '@flybits/webapp-design-system-react';
import SvgFlybitsLogo from '../assets/FlybitsLogo';
import '../styles/pages/Organizations.scss';
import OrganizationsList from '../components/OrganizationList/OrganizationList';
import OrganizationService from '../services/organization.service';
import { Organization, OrganizationResponseBody } from '../model/organizations/organizations';
// import { CreateProjectModal } from '../components/CreateProjectModal/CreateProjectModal';

const Organizations: FunctionComponent<{}> = () => {
  const MAIN_CLASS = 'organizations';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const HEADER_CLASS = `${MAIN_CLASS}__header`;
  const LOGO_CLASS = `${MAIN_CLASS}__logo`;
  const SELECT_CLASS = `${MAIN_CLASS}__select`;
  const CREATE_ORGANIZATION_CLASS = `${MAIN_CLASS}__create-organization`;
  const ORGANIZATION_NO_ORG_CLASS = `${MAIN_CLASS}__no-organization`;
  const EMPTY_STATE_CLASS = `${MAIN_CLASS}__organizations-empty-state`;
  const EMPTY_STATE_DESC_CLASS = `${MAIN_CLASS}__organizations-empty-state-desc`;
  const LINK_CLASS = `${CREATE_ORGANIZATION_CLASS}__link`;
  const GRID_CLASS = `${MAIN_CLASS}__grid`;
  const ORGANIZATION_LIST_CLASS = `${MAIN_CLASS}__organizationList`;
  const [response, setResponse] = useState<Organization[]>([]);

  const [pageIsLoading, setPageLoadingStatus] = useState(true);

  useEffect(() => {
    const fetchOrganizationsAndSignedInUser = async () => {
      const organizationServiceManager = new OrganizationService();
      try {
        const params = {
          limit: 10,
        };
        const organizationResponseObject = await organizationServiceManager.getOrganizations(params);
        if (organizationResponseObject) {
          const transformedResponse: Organization[] = [];
          organizationResponseObject.data.data.map((organization: OrganizationResponseBody) => {
            return transformedResponse.push({
              id: organization.id,
              name: organization.Name,
              logo: organization.Logo,
              creatorID: organization.CreatorID,
              isActive: organization.IsActive,
              isVerified: organization.IsVerified,
              tierID: organization.TierID,
              level: organization.level,
            });
          });
          setResponse(transformedResponse);
        }
      } catch (error) {
        // Add dummy response
        const transformedResponse: Organization[] = [];
        transformedResponse.push({
          id: 'mastercard',
          name: 'Mastercard',
          logo: 'https://download.logo.wine/logo/Mastercard/Mastercard-Logo.wine.png',
          creatorID: 'CreatorID',
          isActive: true,
          isVerified: false,
          tierID: 'organisation.TierID',
          level: 1,
        });
        // Simulate a very slow API response (2s)
        setTimeout(() => {
          setResponse(transformedResponse);
          setPageLoadingStatus(false);
        }, 4000);
        console.error(error);
      }
    };
    fetchOrganizationsAndSignedInUser();
  }, []);

  const toggleModal = () => {
    alert('Nothing to see here');
  };

  return (
    <div className={MAIN_CLASS}>
      <div className={CONTENT_CLASS}>
        <div className={GRID_CLASS}>
          <div className={LOGO_CLASS}>
            <SvgFlybitsLogo width={162} height={47} viewBox="0 0 104 30" {...{ className: '' }} />
          </div>
          <div className={HEADER_CLASS}>
            <div className={SELECT_CLASS}>Select your organization</div>
          </div>
          <div className={ORGANIZATION_LIST_CLASS}>
            {pageIsLoading && (
              <div className={`org-loading ${response.length ? ' hide' : ''}`}>
                <div className="org-loading__content">
                  <svg
                    version="1.1"
                    width="300"
                    viewBox="0 0 33 32"
                    fill="none"
                    x="0px"
                    y="0px"
                    id="flybits_loader"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" className="moving_lines" stroke="#10B2FB" strokeWidth="15" strokeLinecap="round">
                      <line
                        className="line"
                        strokeDashoffset="5208.54"
                        strokeDasharray="0 500 150 1150 200 604.27"
                        x1="2723"
                        y1="-83"
                        x2="816.3"
                        y2="1690.9"
                      />
                      <line
                        className="line"
                        strokeDashoffset="5372.94"
                        strokeDasharray="300 1000 200 1186.4"
                        x1="2448.3"
                        y1="-94.4"
                        x2="408.9"
                        y2="1654.3"
                      />
                      <line
                        className="line"
                        strokeDashoffset="5628.66"
                        strokeDasharray="200 1214.33 300 1100"
                        x1="2187.4"
                        y1="-87.6"
                        x2="-38.7"
                        y2="1634.3"
                      />
                      <line
                        className="line"
                        strokeDashoffset="5843.54"
                        strokeDasharray="10 490 100 1321.77 400 600"
                        x1="1978.6"
                        y1="-87.8"
                        x2="-379.6"
                        y2="1637.2"
                      />
                    </g>
                    <g className="logo">
                      <g className="logo_arrows">
                        <path
                          className="arrow_left"
                          d="M4.72669 9.34723C3.90319 9.43873 0.243157 9.94198 0.0144051 10.7197C-0.214347 11.4517 2.34767 14.0138 2.98818 14.6085C3.17118 12.687 3.76593 10.9027 4.72669 9.34723Z"
                          fill="#051336"
                        />
                        <path
                          className="arrow_bottom"
                          d="M9.34741 27.2359C9.43891 28.0594 9.94217 31.7194 10.7199 31.9481C11.4519 32.1769 14.0139 29.6149 14.6087 28.9744C12.6872 28.7914 10.9029 28.1966 9.34741 27.2359Z"
                          fill="#051336"
                        />
                        <path
                          className="arrow_right"
                          d="M29.0202 17.3541C28.8372 19.2756 28.1967 21.0599 27.2817 22.6154C28.1052 22.5239 31.7653 22.0206 31.994 21.2429C32.2228 20.5109 29.615 17.9489 29.0202 17.3541Z"
                          fill="#051336"
                        />
                        <path
                          className="arrow_top"
                          d="M22.6608 4.72669C22.5693 3.90319 22.0661 0.243157 21.2883 0.0144051C20.5563 -0.214347 17.9485 2.34767 17.3995 2.98818C19.2753 3.17118 21.0596 3.76593 22.6608 4.72669Z"
                          fill="#051336"
                        />
                      </g>
                      <path
                        className="center_circle"
                        d="M25.5431 11.7265C23.2098 6.41946 16.9878 4.08619 11.7265 6.41946C6.46517 8.75273 4.08615 14.9748 6.41942 20.2361C8.75269 25.4974 14.9747 27.8764 20.236 25.5431C25.5431 23.2098 27.9221 16.9878 25.5431 11.7265ZM18.8178 22.3863C15.295 23.9418 11.1775 22.3863 9.62195 18.8636C8.06643 15.3408 9.62194 11.2232 13.1447 9.66774C16.6675 8.11222 20.785 9.66773 22.3405 13.1905C23.9418 16.6675 22.3405 20.7851 18.8178 22.3863Z"
                        fill="#10B2FB"
                      />
                      <path
                        className="center_dot"
                        d="M15.9812 18.7722C17.5225 18.7722 18.772 17.5227 18.772 15.9814C18.772 14.4401 17.5225 13.1907 15.9812 13.1907C14.4399 13.1907 13.1904 14.4401 13.1904 15.9814C13.1904 17.5227 14.4399 18.7722 15.9812 18.7722Z"
                        fill="#333E48"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            )}
            {response.length ? (
              <OrganizationsList list={response} />
            ) : (
              <div className={EMPTY_STATE_CLASS}>
                {pageIsLoading ? (
                  <p className={EMPTY_STATE_DESC_CLASS}>Please wait...</p>
                ) : (
                  <p className={EMPTY_STATE_DESC_CLASS}>You currently don’t have any organizations.</p>
                )}
              </div>
            )}
          </div>
          <div className={CREATE_ORGANIZATION_CLASS}>
            <FlightButton
              label="Create a new organization"
              size="large"
              className={LINK_CLASS}
              theme="link"
              onClick={toggleModal}
            />
          </div>
          <div className={ORGANIZATION_NO_ORG_CLASS}>
            Don’t see your organziation here? Message your system admin to make sure you have been invited to the
            organization.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizations;
