const PASSWORD_VALIDATION =
  'Must be at least 10 characters with at least 1 lowercase letter, uppercase letter, number, and symbol.';
const EMAIL_VALIDATION = 'Must be a valid email.';
const CONFIRMATION_PASSWORD_VALIDATION = 'Passwords must match.';
const FIRST_NAME_REQUIRED = 'First name is required.';
const LAST_NAME_REQUIRED = 'Last name is required.';
const LAST_NAME_VALIDATION = 'Last Name may not contain numbers or special characters.';
const FIRST_NAME_VALIDATION = 'First Name may not contain numbers or special characters.';
const CHANGE_PASSWORD_QUERY_PARAM_ERROR =
  'An error occured while attemping to change the password. Please contact support@flybits.com.';
const USER_TERMS_OF_SERVICE_REQUIRED = 'You must agree to the Terms of Service and Privacy Policy.';
const PASSWORD_REQUIRED = 'Password is required.';
const EMAIL_REQUIRED = 'Email is required.';
const PROJECT_NAME_REQUIRED = 'Project name is required.';
const PROJECT_NAME_TWO_CHARS_REQUIRED = 'Name must be atleast two characters.';
const ONLY_LOWER_CASE = 'Only lowercase, numbers and dashes allowed.';
const SUBDOMAIN_REQUIRED = 'Subdomain is required.';
const SUBDOMAIN_TWO_CHARS_REQUIRED = 'Subdomain must be atleast two characters.';
const EMAIL_TAKEN_ERROR = 'This email is already taken. Please try another.';
const DATASOURCE_NAME_ERROR = 'Datasource Name is required';
const DATASOURCE_NAME_LENGTH_ERROR = 'Datasource Name must be 80 characters or less';
const DATASOURCE_NAME_TAKEN_ERROR = 'Name is already taken.';
const DATASOURCE_GENERIC_URL_ERROR = 'URL must be valid';
const DATASOURCE_PLUGIN_CATEGORY_ERROR = 'Can only contain numbers, letters, and dashes.';
const DATASOURCE_IDENTITY_TAG_ERROR = 'This is a required field.';
const PROVIDER_NAME_ERROR = 'Provider Name is required';
const CLIENT_ID_ERROR = 'Client ID is required';
const CLIENT_SECRET_ERROR = 'Client Secret is required';
const GENERIC_INTERNAL_ERROR =
  'An internal error occured. Please try again or contact an administrator at support@flybits.com';

export {
  PASSWORD_VALIDATION,
  PASSWORD_REQUIRED,
  EMAIL_VALIDATION,
  EMAIL_REQUIRED,
  CONFIRMATION_PASSWORD_VALIDATION,
  LAST_NAME_VALIDATION,
  LAST_NAME_REQUIRED,
  FIRST_NAME_VALIDATION,
  FIRST_NAME_REQUIRED,
  CHANGE_PASSWORD_QUERY_PARAM_ERROR,
  PROJECT_NAME_REQUIRED,
  PROJECT_NAME_TWO_CHARS_REQUIRED,
  ONLY_LOWER_CASE,
  SUBDOMAIN_REQUIRED,
  SUBDOMAIN_TWO_CHARS_REQUIRED,
  USER_TERMS_OF_SERVICE_REQUIRED,
  DATASOURCE_NAME_ERROR,
  DATASOURCE_NAME_LENGTH_ERROR,
  DATASOURCE_NAME_TAKEN_ERROR,
  DATASOURCE_GENERIC_URL_ERROR,
  DATASOURCE_PLUGIN_CATEGORY_ERROR,
  DATASOURCE_IDENTITY_TAG_ERROR,
  PROVIDER_NAME_ERROR,
  GENERIC_INTERNAL_ERROR,
  EMAIL_TAKEN_ERROR,
  CLIENT_ID_ERROR,
  CLIENT_SECRET_ERROR,
};
