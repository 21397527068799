import React, { FunctionComponent } from 'react';
import Jdenticon from '../Jdenticon';
import { FlightCard, getIcon } from '@flybits/webapp-design-system-react';
import './OrganizationListItem.scss';
import { setStoredOrganizationId } from '../../helpers/auth';
interface Props {
  id: string;
  logo: string;
  name: string;
  isActive: boolean;
  isVerified: boolean;
}

const OrganizationListItem: FunctionComponent<Props> = (props: Props) => {
  const { id, name, logo, isActive, isVerified } = props;
  const DEFAULT_CLASS = 'organizationListItem';
  const WRAPPER_CLASS = `${DEFAULT_CLASS}__wrapper`;
  const BORDER_CLASS = `${DEFAULT_CLASS}__border`;
  const ICON_CLASS = `${DEFAULT_CLASS}__project-icon`;
  const INACTIVE_CLASS = `${DEFAULT_CLASS}__inactive`;
  const VERIFIED_CLASS = `${DEFAULT_CLASS}__verified`;

  const handleOnClick = async () => {
    if (!isActive) return;
    try {
      setStoredOrganizationId(id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={WRAPPER_CLASS} key={id} onClick={handleOnClick}>
      <FlightCard
        className={`${BORDER_CLASS} ${!isActive ? INACTIVE_CLASS : ''} ${!isVerified ? VERIFIED_CLASS : ''}`}
        header={name}
        image={
          logo ? (
            <img width="60" src={logo} alt={name} className={ICON_CLASS} />
          ) : (
            <Jdenticon size="60" value={name} className={ICON_CLASS} />
          )
        }
        actionables={<div className="card__icon">{getIcon('baselineArrowRight', '')}</div>}
      />
    </div>
  );
};

export default OrganizationListItem;
