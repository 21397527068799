import React, { FunctionComponent, useState, useEffect } from 'react';
import flybitsLogo from '../assets/logo.png';
import BannerImage from '../components/BannerImage/BannerImage';
import { FlightButton, FlightTextInput, getIcon, FlightModal } from '@flybits/webapp-design-system-react';
import { Link, Redirect } from 'react-router-dom';
import AuthService from '../services/authentication.service';
import { passwordRegex } from '../helpers/auth';
import {
  PASSWORD_VALIDATION,
  PASSWORD_REQUIRED,
  CONFIRMATION_PASSWORD_VALIDATION,
  CHANGE_PASSWORD_QUERY_PARAM_ERROR,
} from '../constants/errors';
import '../styles/pages/SignIn.scss';
import '../styles/pages/PasswordReset.scss';
import '../styles/pages/ChangePassword.scss';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  password: Yup.string().required(PASSWORD_REQUIRED).matches(passwordRegex, PASSWORD_VALIDATION),
  confirmPassword: Yup.string().required(PASSWORD_REQUIRED).matches(passwordRegex, CONFIRMATION_PASSWORD_VALIDATION),
});

const PasswordReset: FunctionComponent<{}> = () => {
  const MAIN_CLASS = 'ChangePassword';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const LINK_CLASS = `${ITEM_CLASS}__link`;
  const initialValues = { password: '', confirmPassword: '' };
  const MODAL_CLASS = `${MAIN_CLASS}__modal`;

  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('code') && queryParams.has('email')) {
      const code = queryParams.get('code');
      const email = queryParams.get('email');
      setCode(code ? code : '');
      setEmail(email ? email : '');
    } else {
      setErrorMessage(CHANGE_PASSWORD_QUERY_PARAM_ERROR);
      setError(true);
    }
  }, []);

  const initChangePassword = async (password: string) => {
    const authServiceManager = new AuthService();
    const changePasswordRequestBody = {
      confirmationKey: code,
      email: email,
      password: password,
    };
    try {
      const response = await authServiceManager.changePassword(changePasswordRequestBody);
      if (response) {
        setIsPasswordChanged(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRedirect = () => {
    setError(false);
    setRedirect(true);
  };

  return (
    <div className={MAIN_CLASS}>
      <FlightModal
        isVisible={error}
        toggleModalShown={handleRedirect}
        scrollable={false}
        size="small"
        warning={false}
        className={MODAL_CLASS}
        header={<span>An Error Occurred</span>}
        content={<span>{errorMessage}</span>}
        footer={
          <div className={`${MODAL_CLASS}__footer`}>
            <FlightButton type="primary" label="Back to sign in" onClick={handleRedirect} />
          </div>
        }
      />
      {redirect && <Redirect to="/signin" />}
      <BannerImage />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          initChangePassword(values.password);
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form className={FORM_CLASS}>
            <img className={`${MAIN_CLASS}__logo`} src={flybitsLogo} alt="logo" />
            {isPasswordChanged ? (
              <div className={`${ITEM_CLASS}__password-change-confirmation`}>
                <h2>{getIcon('checkCircle', null)} Password Sucessfully Reset</h2>
                <FlightButton
                  className={`${ITEM_CLASS}__button`}
                  type="primary"
                  size="large"
                  label="Sign in"
                  onClick={() => {
                    handleRedirect();
                  }}
                />
              </div>
            ) : (
              <div className={`${ITEM_CLASS}__password-change-form`}>
                <div className={`${MAIN_CLASS}__heading-text`}>Reset Password for {email}</div>
                <div className={ITEM_CLASS}>
                  <Field
                    type="password"
                    name="password"
                    className={`${ITEM_CLASS}__input`}
                    as={FlightTextInput}
                    width="100%"
                    label="Password"
                    hasError={touched.password && errors.password ? true : false}
                    value={values.password}
                    errorMessage={<span>{errors.password}</span>}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={ITEM_CLASS}>
                  <Field
                    type="password"
                    name="confirmPassword"
                    className={`${ITEM_CLASS}__input`}
                    as={FlightTextInput}
                    width="100%"
                    label="confirm password"
                    hasError={touched.confirmPassword && errors.confirmPassword ? true : false}
                    value={values.confirmPassword}
                    errorMessage={<span>{errors.confirmPassword}</span>}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <FlightButton
                  className={`${ITEM_CLASS}__button`}
                  type="primary"
                  size="large"
                  label="Reset"
                  onClick={handleSubmit}
                />
              </div>
            )}
            <div className={`${ITEM_CLASS}__signin`}>
              <Link to="/signin" className={LINK_CLASS}>
                Back to sign in
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordReset;
