import React, { useState, useEffect, FunctionComponent, MouseEvent } from 'react';
import * as Yup from 'yup';
import { Field, Formik, Form as FormikForm, getIn } from 'formik';
import CreateContextAttribute from '../../components/AttributeBuilder/CreateContextAttribute';
import { FlightTextInput, FlightTable, FlightButton } from '@flybits/webapp-design-system-react';
import { ConfigurationsProps, CtxAttrOptions, SubmitProps, DefinedConfig, TableRow, 
FieldColumn, DynamicConfigTableHeaders, DynamicConfig, DynamicConfigTableData } from '../../model/datasources/datasources';
import { DATASOURCE_PLUGIN_CATEGORY_ERROR, DATASOURCE_IDENTITY_TAG_ERROR} from '../../constants/errors';
import { definedConfigTableHeaders } from '../../constants/datasource-config';
import SvgConfigLockIcon from '../../components/ConfigLockIcon/ConfigLockIcon';
import { initForm } from '../../helpers/configurations';
import { getCtxAttrCardProps, serializeDefinedConfigData,
serializeDynamicConfigData, sanitizeName } from '../../helpers/datasources';
import FormElementRenderer from '../FormElementRenderer/FormElementRenderer';
import CtxAttributeCard from '../CtxAttributeCard/CtxAttributeCard';
import './Configurations.scss';

const Configurations: FunctionComponent<ConfigurationsProps> = (props) => {
  const [validationSchema, setValidationSchema] = useState({});
  const [dynamicConfigTableError, setDynamicConfigTableError] = useState('');
  // eslint-disable-next-line
  const [category, setCategory] = useState<string>('');
  const MAIN_CLASS = 'Configurations';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ERROR_CLASS = `${FORM_CLASS}__error`;
  const OUTPUTS_CLASS = `${FORM_CLASS}__data-outputs`;
  const {
    basicInfo,
    connectorInstanceId,
    selectedConfigData,
    configValues,
    definedConfigSchema,
    dynamicViewName,
    dynamicConfigSchema,
    dynamicConfigTableHeaders,
    dynamicConfigTableData,
    definedOutputsTableSchema,
    errorsTracker,
    isSaving,
    selectedConfigType,
    setAttributeOnPlugin,
    plugin,
    validateConfigs,
    formData,
    setSelectedConfigData,
    setDynamicConfigTableData,
    setPlugin,
    setDefinedConfigSchema,
    setFormData,
    // transformDynamicConfigSchemaToTable,
    setSelectedConfigType,
    openSidepanel,
    setDefinedOutputTableSchema,
    toggleSidePanel,
    setDynamicViewName,
    updateConnectorInstance,
    setDynamicConfigTableHeaders,
    datasourceStatus,
    removeCtxAttribute,
  } = props;

  const flightButtonProps = {
    className: 'context-attribute-button',
    theme: 'link',
    label: '+ Define Context Attribute',
    size: 'medium',
  };

  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

  useEffect(() => {
    setValidationSchema(Yup.object().shape(initForm(definedConfigSchema)));
  }, [definedConfigSchema]);

  const handleAddDynamicOutput = (e: MouseEvent<HTMLButtonElement>) => {
    transformDynamicConfigSchemaToTable(dynamicConfigSchema);
  };

  useEffect(() => {
    const sanitizedName = sanitizeName(basicInfo.connectorInstanceName);
    const uid = `ctx.integrations-${sanitizedName}.${formData.category}`;
    const pluginValues = plugin[0].values;
    const definedConfigValues = configValues?.definedConfigValues;

    for (const val in pluginValues) {
      pluginValues[val].uid = uid;
    }

    const newPlugin = {
      ...plugin[0],
      uid: uid,
      provider: `integrations-${sanitizedName}`,
      category: formData.category,
      values: pluginValues,
    };

    const updatedDefinedConfigSchema = updateDefinedConfigSchema(definedConfigSchema, definedConfigValues, uid);
    const updatedDynamicConfigTableData = updateDynamicConfigTableData(dynamicConfigSchema, uid);

    setDefinedConfigSchema([...updatedDefinedConfigSchema]);
    setDynamicConfigTableData([...updatedDynamicConfigTableData]);
    setPlugin([{ ...newPlugin }]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.category, basicInfo.connectorInstanceName, plugin[0].uid, plugin[0].category]);

  const updateDynamicConfigTableData = (dynamicConfigSchema: DynamicConfig[], uid: string): DynamicConfigTableData[] => {
    for (let i = 0; i < dynamicConfigSchema.length; i++) {
      const tableRowIdx = i;
      const config = dynamicConfigSchema[i];

      for (let j = 0; j < dynamicConfigTableData.length; j++) {
        config.fieldColumns.forEach((field: FieldColumn, tableCellIdx: number) => {
          const tableData = dynamicConfigTableData[j];

          if (field.name === 'outputFieldName' && tableData.ctxAttributeName) {
            let options: CtxAttrOptions = {
              name: tableData.ctxAttributeName,
              selected: 'dynamic',
              uid,
              tableRowIdx,
              tableCellIdx,
              removeCtxAttribute,
            };

            if (tableData.status === 'enabled') {
              delete options.removeCtxAttribute;
              const ctxAttrCardProps = getCtxAttrCardProps(options);
              tableData.outputFieldName = <CtxAttributeCard {...ctxAttrCardProps} />;
            } else {
              options = { ...options, tableRowIdx: j, tableCellIdx,  removeCtxAttribute }
              const ctxAttrCardProps = getCtxAttrCardProps(options);
              tableData.outputFieldName = <CtxAttributeCard {...ctxAttrCardProps} />;
            }
          }
        });
      }
    }

    return dynamicConfigTableData;
  };

  const updateDefinedConfigSchema = (
    definedConfigSchema: DefinedConfig[],
    definedConfigValues: any[],
    uid: string,
  ): any[] => {
    const hash: any = {};

    if (definedConfigValues) {
      for (let val in definedConfigValues) {
        hash[val] = definedConfigValues[val].mappings;
      }
    }

    return definedConfigSchema.map((config: any, k: number) => {
      const name = config.heading.name;

      config.fields.forEach((field: any, index: number) => {
        const attrName = hash[name]?.[field.name]?.configVal;

        if (config.heading.isOutput) {
          if (attrName && attrName.length && plugin[0].values[attrName]) {
            const uid = plugin[0].values[attrName].uid;
            const options: CtxAttrOptions = {
              name: attrName,
              selected: 'defined',
              uid,
              tableRowIdx: k,
              tableCellIdx: index,
              removeCtxAttribute,
            };

            if (datasourceStatus === 'enabled') {
              delete options.removeCtxAttribute;
            }

            const ctxAttrCardProps = getCtxAttrCardProps(options);
            field.ctxAttributeName = attrName;
            field.ctxAttributeCard = <CtxAttributeCard {...ctxAttrCardProps} />;
          } else if (field.ctxAttributeCard && field.ctxAttributeName) {
            const options: CtxAttrOptions = {
              name: field.ctxAttributeName,
              selected: 'defined',
              uid,
              tableRowIdx: k,
              tableCellIdx: index,
              removeCtxAttribute,
            };

            const ctxAttrCardProps = getCtxAttrCardProps(options);
            field.ctxAttributeCard = <CtxAttributeCard {...ctxAttrCardProps} />;
          }
        } else {
          if (field.type === 'radio' || field.type === 'dropdown') {
            if (!attrName?.length) {
              field.ctxAttributeName = field.inputOpts[0];
              field.ctxAttributeCard = field.inputOpts[0];
            } else {
              field.ctxAttributeName = attrName;
              field.ctxAttributeCard = attrName;
            }
          }
        }
      });
      return config;
    });
  };

  const getAttributeFromPage = async (values: any) => {
    let name = '';

    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        for (let val in values[key]) {
          if (values[key].hasOwnProperty(val) && val === 'name') {
            name = values[key][val];
          }
        }
      }
    }

    if (selectedConfigType === 'defined') {
      if (selectedConfigData) {
        mapDefinedAttributeField(name, values);
      }
    }

    if (selectedConfigType === 'dynamic') {
      if (selectedConfigData) {
        mapDynamicAttributeField(name, values);
      }
    }

    toggleSidePanel();
    setSelectedConfigType('');
  };

  const mapDefinedAttributeField = (name: string, values: any) => {
    const tableRowIdx = selectedConfigData.tableRowIdx;
    const tableCellIdx = selectedConfigData.tableCellIdx;
    const fields = selectedConfigData.config.fields;
    const ctxAttrCardProps = {
      name,
      selected: selectedConfigType,
      uid: plugin[0].uid || '',
      tableRowIdx,
      tableCellIdx,
      removeCtxAttribute,
    };

    fields[tableCellIdx].ctxAttributeCard = <CtxAttributeCard {...ctxAttrCardProps} />;
    fields[tableCellIdx].ctxAttributeName = name;

    setSelectedConfigData({ ...selectedConfigData });
    setDefinedConfigSchema([...definedConfigSchema]);
    setAttributeOnPlugin(values, plugin, name);
    setPlugin([...plugin]);
  };

  const [selectedDataFormat, setSelectedDataFormat] = useState<string>('');

  useEffect(() => {
    if (selectedConfigType === 'dynamic') {
      const dataType:string = getIn(formData, selectedConfigData?.config.dataType.props.elementName) || '';
      setSelectedDataFormat(dataType);
      setIsPanelOpen(false);
    }

    if (selectedConfigType === 'defined') {
      const fieldIdx:number =  selectedConfigData?.tableCellIdx || 0;
      let dataType:string = selectedConfigData?.config.fields[fieldIdx].dataType || '';

      setSelectedDataFormat(dataType);
      setIsPanelOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPanelOpen, selectedConfigType]);

  const mapDynamicAttributeField = (name: string, values: any) => {
    const tableRowIdx = selectedConfigData.tableRowIdx;
    const tableCellIdx = selectedConfigData.tableCellIdx;
    const ctxAttrCardProps = {
      name,
      selected: selectedConfigType,
      uid: plugin[0].uid || '',
      tableRowIdx,
      tableCellIdx,
      removeCtxAttribute,
    };

    selectedConfigData.config['outputFieldName'] = <CtxAttributeCard {...ctxAttrCardProps} />;
    selectedConfigData.config['ctxAttributeName'] = name;

    if (!name.length) {
      selectedConfigData.config['ctxAttributeName'] = '';
      selectedConfigData.config['ctxAttributeCard']= null;
      selectedConfigData.config['outputFieldName'] = selectedConfigData.config['defineBtnElement'];
    } else {
      selectedConfigData.config['ctxAttributeName'] = name;
      selectedConfigData.config['ctxAttributeCard'] = <CtxAttributeCard {...ctxAttrCardProps} />;
      selectedConfigData.config['outputFieldName'] = <CtxAttributeCard {...ctxAttrCardProps} />;
    }

    setSelectedConfigData({ ...selectedConfigData });
    setAttributeOnPlugin(values, plugin, name);
    setPlugin([...plugin]);
  };

  useEffect(() => {
    const definedConfigs = serializeDefinedConfigData(formData,definedConfigSchema);
    const dynamicConfigs = serializeDynamicConfigData(formData,dynamicConfigSchema, dynamicConfigTableData);

    const data: any = {
      configs: {
        identityTag: formData.identityTag,
        definedConfigs: definedConfigs,
        category: formData.category,
        dynamicConfigs: dynamicConfigs,
      },
      draftPlugin: plugin[0],
    };

    validateConfigs(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  const onSubmit = (values: any, { setSubmitting, resetForm, setStatus }: SubmitProps) => {
    const definedConfigs = serializeDefinedConfigData(values, definedConfigSchema);
    const dynamicConfigs = serializeDynamicConfigData(values, dynamicConfigSchema, dynamicConfigTableData);
    const data: any = {
      configs: {
        identityTag: values.identityTag,
        definedConfigs: definedConfigs,
        dynamicConfigs: dynamicConfigs,
      },
      draftPlugin: plugin[0],
    };

    updateConnectorInstance(data);
    setSubmitting(false);
    setDynamicConfigTableError('');
  };

  const handleDefineCtxAttribute = (e: MouseEvent<HTMLButtonElement>, tableConfig: any, configType: string) => {
    setSelectedConfigData(tableConfig);
    setIsPanelOpen(true);
    setSelectedConfigType(configType);
    toggleSidePanel();
  };

  useEffect(() => {
    const definedOutputsTableSchema = transformDefinedConfigSchemaToTable(definedConfigSchema);
    setDefinedOutputTableSchema(definedOutputsTableSchema);
    // eslint-disable-next-line
  }, [definedConfigSchema]);

  const transformDefinedConfigSchemaToTable = (definedConfigSchema: DefinedConfig[]=[]) => {
    const res = [];

    for (let k = 0; k < definedConfigSchema.length; k++) {
      const config = definedConfigSchema[k];
      const isOutput: boolean = config.heading.isOutput;
      const viewName:string = config.heading.viewName;

      const transformedFields = config.fields.map((field: any, index: number) => {
        let ctxAttributeCard  = null;

        if (field.ctxAttributeCard) {
          ctxAttributeCard  = field.ctxAttributeCard;
        } else {
          const tableConfig = { config, tableRowIdx: k, tableCellIdx: index };
          ctxAttributeCard  = <FlightButton
            className="context-attribute-button"
            theme="link"
            label="+ Define Context Attribute"
            onClick={(e) => handleDefineCtxAttribute(e, tableConfig, 'defined')}
          />;
        }

        return {
          key: (index).toString(),
          outputField: <SvgConfigLockIcon name={field.name} />,
          dataType: field.dataType,
          ctxAttributeCard: ctxAttributeCard,
        };
      });

      res.push({ isOutput: isOutput, viewName: viewName, rows: transformedFields });
    }

    return res;
  };

  const transformDynamicConfigSchemaToTable = (dynamicConfigSchema: DynamicConfig) => {
    const dynamicConfigValues = configValues?.dynamicConfigValues;
    const fieldValues = dynamicConfigValues?.heading?.dynamicFields;

    if (dynamicConfigSchema && Array.isArray(dynamicConfigSchema) && dynamicConfigSchema.length) {
      // const columns: TableColumn[] = [];
      const configSchema = dynamicConfigSchema[0];
      const tHeaders:DynamicConfigTableHeaders[]= configSchema.fieldColumns.map((field: any, index: number) => {
        return {
          name: field.label,
          value: 1,
          key: field.name,
          isVisible: true,
          isSortable: false,
        };
      });

      if (fieldValues && dynamicConfigTableData.length < fieldValues.length) {
        formData.dynamicConfigFormData = [...formData.dynamicConfigFormData, ...fieldValues]
        setFormData(formData);

        for (let i = 0; i < fieldValues.length; i++) {
          const status:string = datasourceStatus;
          const row = addRowToDynamicTable(configSchema, tHeaders, fieldValues[i], i, status);
          dynamicConfigTableData.push(row);
          setDynamicConfigTableData([...dynamicConfigTableData]);
        }
      } else {
        const status:string = 'created';
        const row = addRowToDynamicTable(configSchema, tHeaders, null, dynamicConfigTableData.length, status);
        dynamicConfigTableData.push(row);
        setDynamicConfigTableData([...dynamicConfigTableData]);
      }

      setDynamicConfigTableHeaders(tHeaders);
      setDynamicViewName(configSchema.heading.viewName);
    }
  };

  useEffect(() => {
    if (dynamicConfigTableData.length < 1) {
      transformDynamicConfigSchemaToTable(dynamicConfigSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicConfigSchema]);

  const addRowToDynamicTable = (configSchema: any, columns: DynamicConfigTableHeaders[], fieldVal:any, numOfRows: number, status: string) => {
    const row: TableRow = {
      key: (numOfRows).toString(),
      ctxAttributeCard: null,
      ctxAttributeName: '',
      defineBtnElement: '',
      columnName: '',
      dataType: '',
      outputFieldName: '',
      tableName: '',
      status: status,
    };
    const values = plugin[0].values;

    for (let i = 0; i < columns.length; i++) {
      const elementSchema = configSchema.fieldColumns.filter((field: FieldColumn) => {
        return field.name === columns[i].key;
      })[0];

      if (fieldVal) {
        const columnName: string = columns[i].key;

        if (row.status === 'enabled') {
          const uid = values[fieldVal.outputFieldName]?.uid || '';

          if (columnName === 'outputFieldName') {
            const ctxAttrCardProps = {
              name: fieldVal[columnName],
              selected: 'dynamic',
              uid,
            };

            row[columnName] = <CtxAttributeCard { ...ctxAttrCardProps }/>
            row.ctxAttributeName = fieldVal[columnName];
            row.ctxAttributeCard  = <CtxAttributeCard { ...ctxAttrCardProps }/>;
          } else {
            row[columnName] = fieldVal[columnName];
          }
        } else {
          const uid = values[fieldVal.outputFieldName]?.uid || '';

          if (columnName === 'outputFieldName') {
            const ctxAttrCardProps = {
              name: fieldVal[columnName],
              selected: 'dynamic',
              uid,
              tableRowIdx: numOfRows,
              tableCellIdx: i,
              removeCtxAttribute,
            };

            const tableConfig = { config: row, tableRowIdx: numOfRows, tableCellIdx: i };
            const btnProps:any = {
              ...flightButtonProps, onClick: (e:MouseEvent<HTMLButtonElement>) => {
                handleDefineCtxAttribute(e, tableConfig, 'dynamic')
              }
            };

            row[columns[i].key] = <FlightButton  { ...btnProps }/>;
            row['defineBtnElement'] = <FlightButton  { ...btnProps }/>;
            row.ctxAttributeName = fieldVal[columnName] ?  fieldVal[columnName]: '';
            row[columnName] =  fieldVal[columnName] ? <CtxAttributeCard { ...ctxAttrCardProps }/>: row['defineBtnElement'];
            row.ctxAttributeCard = fieldVal[columnName] ? <CtxAttributeCard { ...ctxAttrCardProps }/>: row['defineBtnElement'];

          } else {
            const clonedElementSchema = JSON.parse(JSON.stringify(elementSchema));
            const elementName = `dynamicConfigFormData.${numOfRows+1}.${clonedElementSchema.name}`;

            clonedElementSchema.value = fieldVal[columnName];
            row[columnName] = <FormElementRenderer elementName={elementName} elementSchema={clonedElementSchema} />;
          }
        }
      } else {
        if (columns[i].key === 'outputFieldName') {
          const tableConfig = {
            config: row,
            tableRowIdx:
            numOfRows,
            tableCellIdx: i,
          };

          const btnProps:any = {
            ...flightButtonProps, onClick: (e:MouseEvent<HTMLButtonElement>) => {
              handleDefineCtxAttribute(e, tableConfig, 'dynamic')
            }
          };

          row[columns[i].key] = <FlightButton  { ...btnProps }/>;
          row['defineBtnElement'] = <FlightButton  { ...btnProps }/>;
        } else {
          const elementName = `dynamicConfigFormData.${numOfRows+1}.${elementSchema.name}`;
          row[columns[i].key] = <FormElementRenderer elementName={elementName} elementSchema={elementSchema} />;
        }
      }
    }

    return row;
  };

  const validateCategoryName = () => {
    return /[^A-z 0-9 -]|\^|\s/g.test(plugin[0].category);
  };

  return (
    <div className={MAIN_CLASS}>
      <CreateContextAttribute
        contextPluginData={plugin}
        connectorInstanceId={connectorInstanceId}
        tenantData={[]}
        callback={(values) => getAttributeFromPage(values)}
        isVisible={openSidepanel}
        toggleSidePanel={toggleSidePanel}
        selectedDataFormat={selectedDataFormat}
      />
      <Formik
        enableReinitialize
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
          setFormData(values);
          return (
            <FormikForm className={FORM_CLASS}>
              <div className={`${FORM_CLASS}__identity-wrapper`}>
                <h4 className={`${FORM_CLASS}__subtitle`}>Flybits Identity Tag</h4> 
                <label htmlFor="identityTag" className={`${FORM_CLASS}__label`}>Flybits User Identity Tag</label>
                <Field
                  type="text"
                  as={FlightTextInput}
                  name="identityTag"
                  id="identityTag"
                  hasError={errorsTracker.configurations.identityTag > 0 ? true: false }
                  errorMessage={<p>{DATASOURCE_IDENTITY_TAG_ERROR}</p>}
                  width="100%"
                  value={values.identityTag}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className={`${FORM_CLASS}__input-desc`}>
                  Specify which user attributes will be used for id unification with the data provider.
                </p>
              </div>

              {definedConfigSchema.map((config: any, index: number) => {
                return (
                  <span key={index}>
                    {config.heading.isOutput ? (
                      <></>
                    ) : (
                      <>
                        <h4 className={`${FORM_CLASS}__subtitle`}>{config.heading.viewName}</h4>
                        {config.fields.map((field: any) => {
                          return (
                            <div key={field.name}>
                              <FormElementRenderer elementName={field.name} elementSchema={field} />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </span>
                );
              })}

              <div className={OUTPUTS_CLASS}>
                <h3 className={`${OUTPUTS_CLASS}__title`}>Context Data Outputs</h3>
                <p className={`${OUTPUTS_CLASS}__desc`}>
                  Set which data points you wish to use for context rules by defining context attributes.
                </p>
                <h4 className={`${OUTPUTS_CLASS}__subtitle`}>Context Plugin Schema</h4>

                <div className={`${OUTPUTS_CLASS}__datasource-name`}>
                  {`ctx.${plugin[0].provider}`}.{
                  <Field
                    type="text"
                    name="category"
                    disabled={datasourceStatus === 'created' ? false : true}
                    hasError={errorsTracker.configurations.category > 0 || validateCategoryName() ? true : false}
                    errorMessage={<p>{DATASOURCE_PLUGIN_CATEGORY_ERROR}</p>}
                    as={FlightTextInput}
                    value={values.category}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />}
                </div>

                {definedOutputsTableSchema.map((schema: any, index: number) => {
                  return schema.isOutput && schema.rows.length ? (
                    <span key={index}>
                      <h4 className={`${FORM_CLASS}__subtitle`}>{schema.viewName}</h4>
                      <FlightTable
                        className="table-class"
                        tableHeaders={definedConfigTableHeaders}
                        tableData={schema.rows}
                        isShowHeader={true}
                        hasPaginationBeforeTable={false}
                      />
                    </span>
                  ) : (
                    <></>
                  );
                })}
                <div>
                  <h4 className={`${FORM_CLASS}__subtitle`}>{dynamicViewName}</h4>
                  {
                    errorsTracker.configurations.dynamicConfigs > 0?
                    <p className={ERROR_CLASS}>The fields in each added row must be filled out.</p>: ''
                  }

                  <FlightTable
                    className={`${OUTPUTS_CLASS}__table`}
                    tableHeaders={dynamicConfigTableHeaders}
                    tableData={dynamicConfigTableData}
                    isShowHeader={true}
                    hasPaginationBeforeTable={false}
                  />

                {
                  dynamicConfigTableError.length ?
                  <p>{ dynamicConfigTableError }</p>: <></>
                }
                </div>
                {dynamicConfigTableData.length ?
                  <div className={`${FORM_CLASS}__add`}>
                    <FlightButton
                      theme="link"
                      label="+Add another dynamic output"
                      onClick={(e) => handleAddDynamicOutput(e)}
                    />
                  </div>: <></>
                }
                <div className={`${FORM_CLASS}__submit-btn`}>
                  <FlightButton 
                    type="submit" 
                    label="Save Change"
                    loading={isSaving}
                    disabled={isSaving}
                    onClick={() => undefined} />
                </div>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
};

export default Configurations;
