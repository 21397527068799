import React, { useState } from 'react';
import esPlaceholder from "../../assets/es.svg";
import './CreateProjectModal.scss';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import {
  PROJECT_NAME_REQUIRED,
  ONLY_LOWER_CASE,
  SUBDOMAIN_TWO_CHARS_REQUIRED,
  PROJECT_NAME_TWO_CHARS_REQUIRED,
  SUBDOMAIN_REQUIRED,
} from '../../constants/errors';
import {
  FlightModal,
  FlightTextInput,
  FlightButton,
  FlightTooltip,
  FlightRadioButton,
  getIcon,
} from '@flybits/webapp-design-system-react';
import Jdenticon from '../Jdenticon';
import {
  CREATE_PROJECT_JDENTICOON,
  CREATE_PROJECT_DEVELOPMENT_ENV,
  CREATE_PROJECT_PERSONAL_ENV,
  CREATE_PROJECT_STAGING_ENV,
  CREATE_PROJECT_PRODUCTION_ENV,
} from '../../constants/tooltips';
import ProjectService from '../../services/project.service';
import { CreateProject } from '../../model/projects/projects';
import AuthService from '../../services/authentication.service';
import SettingsService from '../../services/settings.service';
import { ProjectCustomSettings } from '../../model/projectCustomSettings/projectCustomSettings';
import { categories } from '../../constants/projectCategories';

// required validation scehma definition
const subDomainRegex = /^[a-zA-Z0-9-]+$/;
const validationSchema = Yup.object().shape({
  name: Yup.string().required(PROJECT_NAME_REQUIRED).min(2, PROJECT_NAME_TWO_CHARS_REQUIRED),
  subdomain: Yup.string()
    .required(SUBDOMAIN_REQUIRED)
    .matches(subDomainRegex, ONLY_LOWER_CASE)
    .min(2, SUBDOMAIN_TWO_CHARS_REQUIRED),
});

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  fetchProjects: (fetch: boolean) => void;
}

//* form will be populated with these default values /
const initialFormValues = {
  name: 'My Awesome Project',
  environment: 'personal',
  subdomain: 'my-awesome-project',
};

//* CSS class mapping //*
const MAIN_CLASS = 'create-project__modal';
const CONTENT_CLASS = `${MAIN_CLASS}__content`;
const HEADER_CLASS = `${MAIN_CLASS}__header`;
const CONTAINER_CLASS = `${MAIN_CLASS}__container`;
const PLACEHOLDER_TEXT_CLASS = `${MAIN_CLASS}__placeholder__text`;
const PLACEHOLDER_JDENTICON_CLASS = `${MAIN_CLASS}__placeholder__jdenticon`;
const PLACEHOLDER_NAME_CLASS = `${MAIN_CLASS}__placeholder__name`;
const PLACEHOLDER_NAME_ENV_CLASS = `${MAIN_CLASS}__placeholder__name__env`;
const PLACEHOLDER_IMG_CLASS = `${MAIN_CLASS}__placeholder__img`;
const ROW_CLASS = `${MAIN_CLASS}__row`;
const ROW_ICON_CLASS = `${MAIN_CLASS}__row__icon`;
const ROW_LABEL_CLASS = `${MAIN_CLASS}__row__label`;
const ROW_LABEL_FIELD_CLASS = `${MAIN_CLASS}__row__label__field`;
const RADIO_CLASS = `${MAIN_CLASS}__radio`;
const RADIO_LABEL_CLASS = `${MAIN_CLASS}__radio__label`;
const RADIO_GROUP_ICON_CLASS = `${MAIN_CLASS}__radio__group__icon`;
const SUBDOMAIN_LABEL_CLASS = `${MAIN_CLASS}__subdomain__label`;
const SUBDOMAIN_DESCRIPTION_CLASS = `${MAIN_CLASS}__subdomain__description`;
const SUBDOMAIN_EDIT_CLASS = `${MAIN_CLASS}__subdomain__edit`;
const SUBDOMAIN_EDIT_TEXT_CLASS = `${MAIN_CLASS}__subdomain__edit__text`;
const SUBDOMAIN_EDIT_LABEL_CLASS = `${MAIN_CLASS}__subdomain__edit__label`;
const SUBDOMAIN_EDIT_DOMAIN_CLASS = `${MAIN_CLASS}__subdomain__edit__domain`;
const SUBDOMAIN_EDIT_DOMAIN_DEV_CLASS = `${MAIN_CLASS}__subdomain__edit__domain--is-dev`;
const SUBDOMAIN_EDIT_FIELD_DEV_CLASS = `${MAIN_CLASS}__subdomain__edit__field--is-dev`;
const SUBDOMAIN_EDIT_FIELD_CLASS = `${MAIN_CLASS}__subdomain__edit__field`;
const SUBDOMAIN_EDIT_ERROR_CLASS = `${MAIN_CLASS}__subdomain__edit__error`;
const FOOTER_CLASS = `${MAIN_CLASS}__footer`;
const FOOTER_CANCEL_CLASS = `${MAIN_CLASS}__footer__cancel-btn`;
const SUBDOMAIN_EDIT_ICON_CLASS = {
  className: `${MAIN_CLASS}__subdomain__edit__icon`,
};

export function CreateProjectModal(props: Props) {
  const { openModal, toggleModal, fetchProjects } = props;
  const [apiError, setApiError] = useState('');
  const [editSubDomain, setEditSubDomain] = useState(false);
  const [initialValuess, setInitialValues] = useState(initialFormValues);

  //* manages edit-pencil toggle/
  function toggleEditSubDomain() {
    setEditSubDomain((editSubDomain) => !editSubDomain);
  }

  //* makes api calls, if successful returns callback to parent to fetch projects/
  const createProject = async (data: CreateProject) => {
    const projectServiceManager = new ProjectService();
    const authServiceManager = new AuthService();
    const settingsServiceManager = new SettingsService();

    try {
      const projectResponseObject = await projectServiceManager.createProject(data);
      if (projectResponseObject.status === 201) {
        const projectTokenResponse = await authServiceManager.getProjectToken(projectResponseObject.data.id);
        const projectToken = projectTokenResponse.headers['x-authorization'];
        const projectCustomSettings: ProjectCustomSettings = {
          projectToken: projectToken,
          settings: { categories: categories },
          configName: { configName: 'project-custom-content-templates' },
        };
        const projectSettingsResponse = settingsServiceManager.createProjectCustomContentTemplateSettings(
          projectCustomSettings,
        );
        if ((await projectSettingsResponse).status === 200) {
          fetchProjects(true);
          toggleModal();
        }
      }
    } catch (error) {
      setEditSubDomain(true);
      setApiError(error.response?.data);
    }
  };

  //* handles api error message locally
  function resetApiErrorMessage() {
    setApiError('');
  }

  return (
    <div className={MAIN_CLASS}>
      {/* {Begin New Project Modal} */}
      <Formik
        initialValues={initialValuess}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let subDomainValueForServer = '';
          if (values.subdomain.indexOf(values.environment) === -1) {
            subDomainValueForServer =
              values.environment.toLocaleLowerCase() + '-' + values.subdomain.toLocaleLowerCase();
          }
          const submitData: CreateProject = {
            name: values.name,
            subDomain: subDomainValueForServer,
          };
          createProject(submitData);
          setSubmitting(true);
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, setFieldValue, handleReset }) => (
          <FlightModal
            isVisible={openModal}
            toggleModalShown={(e: React.FormEvent<HTMLInputElement>) => {
              handleReset(e);
              toggleModal();
            }}
            scrollable={false}
            size="large"
            content={
              <div className={CONTENT_CLASS}>
                <span className={HEADER_CLASS}>Create New Project</span>
                <div>
                  <div>
                    <div className={CONTAINER_CLASS}>
                      {values.subdomain === '' ? (
                        <div className={PLACEHOLDER_TEXT_CLASS}>
                          {values.environment.toLocaleLowerCase() +
                            '-' +
                            values.name.replace(/\s+/g, '-').toLowerCase()}
                          .flybits.com/#/experiences
                        </div>
                      ) : (
                        <div className={PLACEHOLDER_TEXT_CLASS}>
                          {values.environment.toLocaleLowerCase() + '-' + values.subdomain}
                          .flybits.com/#/experiences
                        </div>
                      )}
                      <Jdenticon size="24" value={values.name} className={PLACEHOLDER_JDENTICON_CLASS} />
                      <div className={PLACEHOLDER_NAME_CLASS}>
                        {values.name + ' '}
                        {values.name !== '' ? (
                          <span className={PLACEHOLDER_NAME_ENV_CLASS}>({values.environment})</span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <img
                          height="100%"
                          width="100%"
                          src={esPlaceholder}
                          alt="experiencestudio-placeholder"
                          className={PLACEHOLDER_IMG_CLASS}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className={ROW_CLASS}>
                        <FlightTooltip
                          direction="right bottom"
                          isEnabled={true}
                          description={CREATE_PROJECT_JDENTICOON}
                        >
                          <Jdenticon size="53" value={values.name} className={ROW_ICON_CLASS} />
                        </FlightTooltip>
                        <div className={ROW_LABEL_CLASS}>
                          Project Name
                          <Field
                            type="text"
                            name="name"
                            as={FlightTextInput}
                            hasError={touched.name && errors.name ? true : false}
                            className={ROW_LABEL_FIELD_CLASS}
                            value={values.name}
                            errorMessage={<span>{errors.name}</span>}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                              handleChange(e);
                              const cleanInputForSubdomain = e.currentTarget.value
                                .replace(/[^a-zA-Z0-9]/g, '-')
                                .toLowerCase();
                              setFieldValue('subdomain', cleanInputForSubdomain);
                              // }
                            }}
                            onBlur={handleBlur}
                          />{' '}
                        </div>
                      </div>
                    </div>
                    <div className={RADIO_CLASS}>
                      <div className={RADIO_LABEL_CLASS}>
                        Environment
                        <div>
                          <FlightTooltip direction="bottom" isEnabled={true} description={CREATE_PROJECT_PERSONAL_ENV}>
                            <Field
                              type="radio"
                              name="environment"
                              label="Personal"
                              checked={values.environment === 'personal' ? true : false}
                              value="personal"
                              className={RADIO_GROUP_ICON_CLASS}
                              as={FlightRadioButton}
                              onSelect={() => setFieldValue('environment', 'personal')}
                            />
                          </FlightTooltip>
                          <FlightTooltip
                            direction="bottom"
                            isEnabled={true}
                            description={CREATE_PROJECT_DEVELOPMENT_ENV}
                          >
                            <Field
                              type="radio"
                              name="environment"
                              label="Development"
                              checked={values.environment === 'development' ? true : false}
                              value="development"
                              className={RADIO_GROUP_ICON_CLASS}
                              as={FlightRadioButton}
                              onSelect={() => setFieldValue('environment', 'development')}
                            />
                          </FlightTooltip>
                        </div>
                        <div>
                          <FlightTooltip direction="right" isEnabled={true} description={CREATE_PROJECT_STAGING_ENV}>
                            <Field
                              type="radio"
                              name="environment"
                              label="Staging"
                              checked={values.environment === 'staging' ? true : false}
                              value="personal"
                              className={RADIO_GROUP_ICON_CLASS}
                              as={FlightRadioButton}
                              onSelect={() => setFieldValue('environment', 'staging')}
                            />
                          </FlightTooltip>
                          <FlightTooltip
                            direction="bottom"
                            isEnabled={true}
                            description={CREATE_PROJECT_PRODUCTION_ENV}
                          >
                            <Field
                              type="radio"
                              name="environment"
                              label="Production"
                              checked={values.environment === 'production' ? true : false}
                              value="production"
                              className={RADIO_GROUP_ICON_CLASS}
                              as={FlightRadioButton}
                              onSelect={() => setFieldValue('environment', 'production')}
                            />
                          </FlightTooltip>
                        </div>{' '}
                      </div>
                    </div>
                    <div className={SUBDOMAIN_LABEL_CLASS}>Subdomain</div>
                    <div className={SUBDOMAIN_DESCRIPTION_CLASS}>
                      This is your experience studio subdomain. You cannot change your project URL after project
                      creation
                    </div>
                    {!editSubDomain ? (
                      <div className={SUBDOMAIN_EDIT_CLASS}>
                        <span onClick={() => toggleEditSubDomain()}>
                          {getIcon('pencil', SUBDOMAIN_EDIT_ICON_CLASS)}
                        </span>
                        <span className={SUBDOMAIN_EDIT_TEXT_CLASS}>
                          {values.name === '' ? '' : `${values.environment}-${values.subdomain}-flybits.com`}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span className={SUBDOMAIN_EDIT_LABEL_CLASS}>{values.environment}-</span>
                        <span
                          className={
                            values.environment === 'development'
                              ? SUBDOMAIN_EDIT_DOMAIN_DEV_CLASS
                              : SUBDOMAIN_EDIT_DOMAIN_CLASS
                          }
                        >
                          .flybits.com
                        </span>
                        <Field
                          type="text"
                          name="subdomain"
                          as={FlightTextInput}
                          hasError={
                            (touched.subdomain && errors.subdomain ? true : false) || (apiError !== '' ? true : false)
                          }
                          className={
                            values.environment === 'development'
                              ? SUBDOMAIN_EDIT_FIELD_DEV_CLASS
                              : SUBDOMAIN_EDIT_FIELD_CLASS
                          }
                          value={values.subdomain.toLowerCase()}
                          errorMessage={
                            <div className={SUBDOMAIN_EDIT_ERROR_CLASS}>{errors.subdomain || apiError}</div>
                          }
                          onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            handleChange(e);
                            resetApiErrorMessage();
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }
            footer={
              <div className={FOOTER_CLASS}>
                <FlightButton
                  onClick={(e: React.FormEvent<HTMLInputElement>) => {
                    handleReset(e);
                    setInitialValues(initialFormValues);
                    resetApiErrorMessage();
                    toggleModal();
                    setEditSubDomain(false);
                  }}
                  className={FOOTER_CANCEL_CLASS}
                  label="Cancel"
                  type="secondary"
                />
                <FlightButton type="primary" label="Create Project" onClick={handleSubmit} disabled={!isValid} />
              </div>
            }
          />
        )}
      </Formik>
    </div>
  );
}
