import React, { FunctionComponent } from 'react';
import { FlightButton } from '@flybits/webapp-design-system-react';
import './DatasourceHeader.scss';
import { ShareDataSourceProject } from '../../model/projects/projects';

interface Props {
  connectorInstanceName: string;
  selectedProjects: ShareDataSourceProject[];
  numOfSelectedProjects: number;
  handleShare: () => void;
}

const DatasourceHeader: FunctionComponent<Props> = (props) => {
  const { connectorInstanceName, numOfSelectedProjects, handleShare } = props;
  const MAIN_CLASS = `DatasourceHeader`;

  const handleDatasourceShare = () => {
    handleShare();
  };

  return (
    <div className={MAIN_CLASS}>
      <p className={`${MAIN_CLASS}__title`}>{connectorInstanceName}</p>
      <div className={`${MAIN_CLASS}__right`}>
        <p>Shared with {numOfSelectedProjects} Projects</p>
        <FlightButton
          label={numOfSelectedProjects === 0 ? 'Share' : 'Share Settings'}
          size="large"
          iconRight={numOfSelectedProjects === 0 ? 'baselineArrowRight' : ''}
          onClick={handleDatasourceShare}
        />
      </div>
    </div>
  );
};

export default DatasourceHeader;
