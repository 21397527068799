import React from 'react';

const SvgFlybitsLogo = (props?: any) => (
<svg width={104} height={30} {...props}>
<path d="M39.8996 5.99524C39.1403 5.99524 38.4395 6.28726 38.4395 6.43327V23.3121C38.4395 24.743 39.1695 25.1518 40.1916 25.1518H40.6004C41.5933 25.1518 42.2941 24.7137 42.2941 23.3121V9.09066C42.3233 6.60848 41.0676 5.99524 39.8996 5.99524Z" fill="#051336"/>
<path d="M66.4147 22.0856C64.5457 22.0856 63.0856 20.7715 63.0856 18.8733V16.4204C63.6697 15.0771 64.8377 14.2302 66.4439 14.2302C68.4588 14.2302 69.8605 15.8071 69.8605 18.1725C69.8605 20.4795 68.488 22.0856 66.4147 22.0856ZM67.4075 11.1056C65.5094 11.1056 64.0201 11.9525 63.0856 13.2958V9.09066C63.0856 6.60848 61.8299 5.99524 60.6911 5.99524C59.9318 5.99524 59.231 6.28726 59.231 6.43327V23.2828C59.231 24.7137 59.9318 25.1226 60.9831 25.1226H61.3919C62.3848 25.1226 63.0856 24.6845 63.0856 23.2828V22.8448C63.7865 24.1297 65.3342 25.1518 67.3491 25.1518C71.0578 25.1518 73.5984 22.2608 73.5984 18.1141C73.5984 13.8214 71.0286 11.1056 67.4075 11.1056Z" fill="#051336"/>
<path d="M36.483 5.99524H26.0287C26.0287 5.99524 26.0287 5.99524 25.9995 5.99524H25.4155H25.3863H24.9774C23.9846 5.99524 23.2545 6.46247 23.2545 7.86417V23.3121C23.2545 24.743 23.9846 25.1518 25.0066 25.1518H25.4155C26.4083 25.1518 27.1092 24.7137 27.1092 23.3121V17.8805H30.263C30.2922 17.8805 30.3214 17.8805 30.3506 17.8805C32.8328 17.8805 33.446 16.8584 33.446 15.7195C33.446 14.9603 33.1248 14.6098 33.008 14.6098H30.5258C30.4382 14.6098 30.3798 14.6098 30.2922 14.6098H27.1384V9.90831H33.8257C36.3078 9.90831 36.9211 8.65262 36.9211 7.51374C36.9211 6.72529 36.629 5.99524 36.483 5.99524Z" fill="#051336"/>
<path d="M98.5653 16.6839C96.7547 16.3043 95.7035 16.129 95.7035 15.1654C95.7035 14.3185 96.5211 13.8513 97.9812 13.8513C99.5289 13.8513 100.989 14.5813 101.894 15.6326C101.953 15.7202 103.121 14.9902 103.121 13.9973C103.121 12.4496 101.339 11.1355 97.7184 11.1355C94.331 11.1355 92.1408 12.8584 92.1408 15.4574C92.1408 18.4944 95.0026 19.1077 96.8424 19.5165C98.6529 19.8961 99.7042 20.0713 99.7042 21.0642C99.7042 21.9111 98.8573 22.3491 97.3388 22.3491C95.3531 22.3491 94.1266 21.5606 93.2213 20.5678C93.1337 20.4801 92.0532 21.181 92.0532 22.1447C92.0532 23.6924 93.6593 25.0357 97.5724 25.0357C101.252 25.0357 103.471 23.4004 103.471 20.7138C103.5 17.7352 100.463 17.0927 98.5653 16.6839Z" fill="#051336"/>
<path d="M76.6068 11.1355C75.8476 11.1355 75.1467 11.4275 75.1467 11.5735V23.2836C75.1467 24.7145 75.8768 25.1525 76.8989 25.1525H77.3077C78.3005 25.1525 79.0014 24.7145 79.0014 23.2836V14.2893C79.0014 11.7487 77.7457 11.1355 76.6068 11.1355Z" fill="#051336"/>
<path d="M79.0593 7.98118C79.0593 9.58729 77.1904 10.7846 75.4966 9.47048C74.2117 7.77677 75.3798 5.90784 76.9859 5.90784C78.1248 5.93704 79.0593 6.8423 79.0593 7.98118Z" fill="#051336"/>
<path d="M89.717 21.2099C89.3081 21.7355 88.7825 22.1152 87.994 22.1152C86.9428 22.1152 86.5047 21.4435 86.5047 20.1294V14.1138H88.8701C90.009 14.1138 90.4762 13.6174 90.4762 12.6829V12.4785C90.4762 11.5732 90.009 11.106 88.9285 11.106H86.5047V10.6972C86.5047 8.21499 85.4535 7.60175 84.1978 7.60175C83.4385 7.60175 82.7961 7.89377 82.7961 8.03978V9.35387C82.7961 9.38307 82.7961 9.41227 82.7961 9.44147V11.106H82.1244C81.0147 11.106 80.5183 11.6024 80.5183 12.5369V12.7705C80.5183 13.6466 81.0439 14.1138 82.1244 14.1138H82.7669V20.5675C82.7669 23.5753 84.1394 25.1522 87.0888 25.1522C89.2789 25.1522 90.6222 24.0425 90.6222 22.6992C90.6514 21.6479 89.7754 21.1223 89.717 21.2099Z" fill="#051336"/>
<path d="M55.7261 11.1355C54.8793 11.1355 54.0616 11.5735 53.5944 13.092L51.0246 20.7138L48.1336 13.0628C47.6372 11.5443 46.8779 11.1355 45.9727 11.1355C44.8046 11.1355 43.7825 12.0408 43.8701 12.2452L48.3964 22.0863L49.3309 24.1012L47.5204 28.9195C47.4036 29.124 48.4548 30 49.5645 30C50.4114 30 51.1706 29.562 51.7547 28.1019L57.8287 12.1868C57.8871 12.0116 56.8358 11.1355 55.7261 11.1355Z" fill="#051336"/>
<path d="M10.2009 11.982C11.1847 11.982 11.9822 11.1844 11.9822 10.2006C11.9822 9.21684 11.1847 8.41931 10.2009 8.41931C9.21708 8.41931 8.41956 9.21684 8.41956 10.2006C8.41956 11.1844 9.21708 11.982 10.2009 11.982Z" fill="#333E48"/>
<path d="M16.3041 7.48495C14.8147 4.09751 10.8433 2.60821 7.48504 4.09751C4.1268 5.58681 2.60829 9.55829 4.0976 12.9165C5.5869 16.2748 9.55838 17.7933 12.9166 16.304C16.3041 14.8147 17.8226 10.8432 16.3041 7.48495ZM12.0114 14.289C9.7628 15.2819 7.13461 14.289 6.14174 12.0405C5.14887 9.79191 6.14174 7.16373 8.3903 6.17086C10.6389 5.17799 13.267 6.17086 14.2599 8.41941C15.282 10.6388 14.2599 13.267 12.0114 14.289Z" fill="#10B2FB"/>
<path d="M3.01701 5.96643C2.49137 6.02483 0.155205 6.34606 0.00919465 6.84249C-0.136816 7.30972 1.4985 8.94504 1.90733 9.32467C2.02414 8.09818 2.40376 6.9593 3.01701 5.96643Z" fill="#051336"/>
<path d="M5.96643 17.3845C6.02483 17.9101 6.34606 20.2463 6.84249 20.3923C7.30972 20.5383 8.94504 18.903 9.32466 18.4941C8.09818 18.3773 6.9593 17.9977 5.96643 17.3845Z" fill="#051336"/>
<path d="M18.5232 11.0768C18.4064 12.3033 17.9976 13.4421 17.4136 14.435C17.9392 14.3766 20.2754 14.0554 20.4214 13.559C20.5674 13.0917 18.9029 11.4564 18.5232 11.0768Z" fill="#051336"/>
<path d="M14.4642 3.01701C14.4058 2.49137 14.0846 0.155205 13.5881 0.00919465C13.1209 -0.136816 11.4564 1.4985 11.106 1.90733C12.3032 2.02414 13.4421 2.40376 14.4642 3.01701Z" fill="#051336"/>
</svg>

);

export default SvgFlybitsLogo;
