import React, { FunctionComponent } from 'react';
import './ConnectorsList.scss';
import { Connector } from '../../model/connectors/connectors';
import ConnectorsListItem from '../ConnectorsListItem/ConnectorsListItem';

interface Props {
  list: Array<Connector>;
  handleRedirectToDatasourceSharingPage: (connectorId: string) => void;
}

const ConnectorsList: FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'connectors-list';
  const WRAPPER_CLASS = `${MAIN_CLASS}__wrapper`;

  const { list, handleRedirectToDatasourceSharingPage } = props;

  return (
    <div className={MAIN_CLASS}>
      <div className={WRAPPER_CLASS}>
        {list.map((item: Connector) => (
          <ConnectorsListItem
            item={item}
            key={item.id}
            handleRedirectToDatasourceSharingPage={handleRedirectToDatasourceSharingPage}
          />
        ))}
      </div>
    </div>
  );
};

export default ConnectorsList;
