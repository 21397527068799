import React, { FunctionComponent } from 'react';
import {
  TextField,
  TextAreaField,
  RadioFields,
  SelectField,
  PasswordField,
  CheckboxField,
} from '../../components/FormElements/FormElements';

interface IElementSchema {
  label: string;
  required: boolean;
  description: string;
  inputOpts: string[];
  isTable: boolean;
  type: string;
  inputType: string;
  ctxAttributeCard?: string;
  value: string;
};

interface IProps {
  elementName: string;
  elementSchema: IElementSchema;
};

const FormElementRenderer:FunctionComponent<IProps> = (props) => {
  const {elementName, elementSchema} = props;

  const childProps = {
    name: elementName,
    label: elementSchema.label,
    required: elementSchema.required,
    description: elementSchema.description,
    inputOpts: elementSchema.inputOpts,
    isTable: elementSchema.isTable,
    type: elementSchema.type,
    inputType: elementSchema.inputType,
    ctxAttributeCard: elementSchema.ctxAttributeCard,
    value: elementSchema.value,
  };

  return (
    <>
      {((childProps) => {
        switch (childProps.type || childProps.inputType) {
          case 'text':
            return <TextField {...childProps} />;
          case 'checkbox':
            return <CheckboxField {...childProps} />;
          case 'radio':
            return <RadioFields {...childProps} />;
          case 'password':
            return <PasswordField {...childProps} />;
          case 'textarea':
            return <TextAreaField {...childProps} />;
          case 'dropdown':
            return <SelectField {...childProps} />;
          default:
            break;
          }
        }
      ) (childProps)}
    </>
  )
};

export default FormElementRenderer;