import React, { FunctionComponent } from 'react';
import './DatasourceSidebar.scss';
import { getIcon } from '@flybits/webapp-design-system-react';

interface IProps {
  currentTab: string;
  errorsTracker: any;
  handleTabChange: (e: React.MouseEvent<HTMLElement>, currentTab: string) =>void;
}

const DatasourceSidebar: FunctionComponent<IProps> = (props) => {
  const { currentTab, handleTabChange, errorsTracker } = props;

  const MAIN_CLASS = 'DatasourcesSidebar';
  const SIDEBAR_LIST_CLASS = `${MAIN_CLASS}__list`;
  const ERROR_CLASS = `${SIDEBAR_LIST_CLASS}__error`;
  const ICON_CLASS = {
    className: `${ERROR_CLASS}__icon`,
  };

  return (
    <div className={MAIN_CLASS}>
      <ul className={SIDEBAR_LIST_CLASS}>
        <li className={`${SIDEBAR_LIST_CLASS}__item`}>
          <a href="/#"
            className={currentTab === 'datasource_info' ? 'is-active' : ''}
            onClick={(e) => {
              handleTabChange(e, 'datasource_info');
            }}
          >
            Datasource Info <span className={ERROR_CLASS}>{ errorsTracker.basicInfo.count > 0  ? getIcon('infoFilled', ICON_CLASS): '' }</span>
          </a>
        </li>
        <li className={`${SIDEBAR_LIST_CLASS}__item`}>
          <a href="/#"
            className={currentTab === 'configurations' ? 'is-active' : ''}
            onClick={(e) => {
              handleTabChange(e, 'configurations');
            }}
          >
            Configurations <span className={ERROR_CLASS}>{ errorsTracker.configurations.count > 0  ? getIcon('infoFilled', ICON_CLASS): '' }</span>
          </a>
        </li>
        <li className={`${SIDEBAR_LIST_CLASS}__item`}>
          <a href="/#"
            className={currentTab === 'authorization' ? 'is-active' : ''}
            onClick={(e) => {
              handleTabChange(e, 'authorization');
            }}
          >
            Authorization
          </a>
        </li>
      </ul>
    </div>
  );
}

export default DatasourceSidebar;