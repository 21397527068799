import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import './SearchBar.scss';
import { debounce } from 'lodash';

interface Props {
  searchText: (e: any) => void;
}

const SearchBar: FunctionComponent<Props> = (props: Props) => {
  const [openSearch] = useState(false);

  const delayCallBack = debounce(
    (searchTerm: string) => {
      props.searchText(searchTerm);
    },
    250,
    { maxWait: 500 },
  );

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    delayCallBack(e.target.value);
  };

  const focusableRef = useRef<any>();

  useEffect(() => {
    focusableRef?.current?.focus();
  }, [openSearch]);

  return (
    <div className="searchBox">
      <input type="text" placeholder="Search" onChange={(e) => handleInput(e)} ref={focusableRef} />
    </div>
  );

};
export default SearchBar;
