/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import './ApplicationCard.scss';
interface Props {
  svg: string;
  name: string;
  url: string;
  classSelector: string;
  altImageText?: string;
}

const ApplicationCard = (props: Props) => {
  const { svg, name, url, classSelector, altImageText } = props;
  const MAIN_CLASS = `ApplicationCard`;
  const MAIN_ClASS_CARD_CONTAINER = `${MAIN_CLASS}__${classSelector}`;
  const MAIN_CLASS_IMAGE_CONTAINER = `${MAIN_CLASS}__image-container`;
  const MAIN_CLASS_IMAGE = `${MAIN_CLASS}__image`;
  const MAIN_CLASS_TITLE = `${MAIN_CLASS}__title`;

  const handleOnClick = () => {
    window.location.replace(url);
  };

  return (
    <div className={MAIN_CLASS} onClick={handleOnClick}>
      <div className={MAIN_ClASS_CARD_CONTAINER}>
        <div className={MAIN_CLASS_IMAGE_CONTAINER}>
          <img className={MAIN_CLASS_IMAGE} height="100%" width="100%" src={svg} alt={altImageText} />
        </div>
        <div className={MAIN_CLASS_TITLE}>
          <h4>{name}</h4>
        </div>
      </div>
    </div>
  );
};

export default ApplicationCard;
