import * as Yup from 'yup';

interface DynamicFieldsTableRowData {
  tableName: string;
  columnName: string;
  dataType: string;
  outputFieldName: string;
}

interface Heading {
  name: string;
  viewName: string;
  description: string;
  isOutput: boolean;
  dynamicFields?: DynamicFieldsTableRowData[];
}

interface FieldColumn {
  name: string;
  viewName: string;
  description: string;
  inputType: string;
  inputOpts?: string[];
}

interface DynamicConfig {
  heading: Heading;
  fieldColumns: FieldColumn[];
}

interface DynamicConfigField {
  type: string;
  name: string;
  label: string;
  description: string;
  dataType: string;
  metadata: any;
  required: boolean;
  inputOpts?: string[];
  isTable: boolean;
  ctxAttributeName: string;
  ctxAttributeCard: any;
}

interface DynamicConfigFields {
  heading: Heading;
  fieldColumns: DynamicConfigField[];
}

//Defined Configs
export const transformDefinedConfigFields = (definedConfigs: any): any => {
  const newDefinedConfigs = definedConfigs.map((config:any) => {
    return {
        heading: config.heading,
        fields: config.fields.map((field:any) => {
          return  {
            type: field.inputType === 'textbox' ? 'text' : field.inputType,
            name: field.name,
            label: field.viewName || field.name,
            description: field.description,
            dataType: field.inputOpts && field.inputOpts.length ? 'string': field.dataType,
            metadata: field.metadata,
            required: field.isRequired,
            inputOpts: field.inputOpts,
            isTable: false,
            ctxAttributeName: '',
            ctxAttributeCard: null,
          };
      })
    };
  });

  return newDefinedConfigs;
}

//Dynamic Configs
export const transformDynamicConfigFields = (dynamicConfigs: DynamicConfig[]): any => {

  for (const config of dynamicConfigs) {
    if (config) {
      const outputField = config.fieldColumns.filter(item => item.name === 'outputFieldName')[0];
      const dataTypeField = config.fieldColumns.filter(item => item.name === 'outputFieldName')[0];

      if (!outputField) {
        config.fieldColumns.push({
          description: "Declare the output field name for the data, eg. SF_Customer_CarMake",
          inputOpts: [],
          inputType: "textbox",
          name: "outputFieldName",
          viewName: "Output Field Name",
        })
      }

      if (!dataTypeField) {
        config.fieldColumns.push({
          description: "Choose the type of the data from the data source",
          inputOpts:  ["string", "integer", "float", "boolean"],
          inputType: "dropdown",
          name: "dataType",
          viewName: "Data Type",
        });
      }
    }
  }

  for (const config of dynamicConfigs) {
    if (config && config.fieldColumns) {
      const field = config.fieldColumns[config.fieldColumns.length - 1];

      if (field.name !== 'outputFieldName') {
        let idx = 0;

        for (let i = 0; i < config.fieldColumns.length; i++) {
          if (config.fieldColumns[i].name === 'outputFieldName') {
            idx = i;
            break;
          }
        };

        const temp = field;
        config.fieldColumns[config.fieldColumns.length - 1] = config.fieldColumns[idx];
        config.fieldColumns[idx] = temp;
      }
    }
  }

  const transformedDynamicFields = dynamicConfigs.map((config:any) => {
    return {
        heading: config.heading,
        fieldColumns: config.fieldColumns.map((field:any) => {
          return  {
            type: field.inputType === 'textbox' ? 'text' : field.inputType,
            name: field.name,
            label: field.name === 'outputFieldName' ? 'Context Attribute':  field.viewName,
            description: field.description,
            // dataType: field.dataType === 'dropdown' ? 'text': field.dataType,
            dataType: field.dataType,
            metadata: field.metadata,
            required: field.isRequired,
            inputOpts: field.inputOpts,
            isTable: true,
            ctxAttributeName: '',
            ctxAttributeCard: null,
          };
      })
    };
  });

  return transformedDynamicFields;
}

export const initForm = (definedConfigSchema: any): any => {
  const _formData: any = {};
  const _validationSchema: any = {};

  for (const config of definedConfigSchema) {
    const fields = config.fields;

    for (const field of fields) {
      const { type, name, required } = field;
      _formData[name] = '';
      switch (type) {
        case 'text':
          if (required) {
            _validationSchema[name] = Yup.string().required('Enter name');
          } else {
            _validationSchema[name] = Yup.string();
          }

          break;
        case 'checkbox':
          _validationSchema[name] = Yup.boolean();
          break;
        case 'radio':
          _validationSchema[name] = Yup.string();
          break;
        case 'password':
          _validationSchema[name] = Yup.string();
          break;
        case 'textarea':
          _validationSchema[name] = Yup.string();
          break;
        case 'dropdown':
          _validationSchema[name] = Yup.array().oneOf(field.inputOpts.map((o: any) => o.value));
          break;
        default:
          break;
      }

      if (required) {
        _validationSchema[name] = _validationSchema[name].required('Required');
      }
    }
  }

  return { ..._validationSchema };
  // setValidationSchema(Yup.object().shape({ ..._validationSchema }));
};

