import React, { FunctionComponent } from 'react';
import { FlightButton, FlightTextInput, getIcon } from '@flybits/webapp-design-system-react';
import { CLIENT_ID_ERROR, CLIENT_SECRET_ERROR } from '../../constants/errors';
import DatasourceService from '../../services/datasources.service';
import ConnectorService from '../../services/connectors.service';
import './Authorization.scss';
import { throttle } from 'lodash';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

interface IAuth {
  clientId: string;
  clientSecret: string;
  authStatus: string;
}

interface IProps {
  authConfigs: IAuth;
  id: string;
  connectorId: string;
  setAuth: (arg: IAuth) => void;
}

const Authorization: FunctionComponent<IProps> = (props) => {
  const { authConfigs, setAuth, id, connectorId } = props;
  const validationSchema = Yup.object().shape({
    clientId: Yup.string().required(CLIENT_ID_ERROR),
    clientSecret: Yup.string().required(CLIENT_SECRET_ERROR),
  });

  const MAIN_CLASS = 'Authorization';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;

  const poll = throttle(async (id: string) => {
    const params = {
      id: id,
    };

    try {
      const datasourceServiceManager = new DatasourceService();
      return datasourceServiceManager.getDatasources(params);
    } catch(error) {
      console.log(error);
    }
  }, 500);

  const retieveAuthTypes = async () => {
    const connectorServiceManager = new ConnectorService();
    try {
      const connectorParams = {
        id: connectorId,
        type: 'available',
      };
      const connectorResponseObject = await connectorServiceManager.getConnectors(connectorParams);
      const authTypes = connectorResponseObject?.data?.data[0]?.authTypes[0];
      return authTypes;
    } catch (error) {
      console.log(error);
    }
  };

  const handleAuthorize = async () => {
    let timeout: number = 0;
    try {
      const authTypes = await retieveAuthTypes();
      const datasourceServiceManager = new DatasourceService();
      const authorizationParams = {
        authTag: authTypes.authTag,
        connectorInstanceId: id,
        clientId: authConfigs.clientId,
        clientSecret: authConfigs.clientSecret,
      };
      try {
        const authorizationResponse = await datasourceServiceManager.authorizeDatasource(authorizationParams);
        if (authorizationResponse?.data?.authUrl) {
          const authWindow = window.open(authorizationResponse.data.authUrl, '_blank');
          authWindow?.focus();
          const numOfRepeats = 60;
          let i = 0;

          while (i < numOfRepeats) {
            try {
              const response = await poll(id);
              if (!response?.data?.data[0]?.auth?.status.length) {
                timeout = (function(i: number) {
                 return window.setTimeout(() => {
                    i++;
                  }, 500);
                }(i));
              } else if (response?.data?.data[0]?.auth?.status === 'connected') {
                authWindow?.close();
                setAuth({ ...authConfigs, authStatus: 'connected' });
                window.clearTimeout(timeout || 0);
                break;
              } else {
                if (window) {
                  window.close();
                  setAuth({ ...authConfigs, authStatus: 'failed' });
                  window.clearTimeout(timeout || 0);
                }
                break;
              }
            } catch(e) {
              console.log(e);
              if (window) {
                window.close();
                setAuth({ ...authConfigs, authStatus: 'failed' });
              }
              break;
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    } catch(error) {
      console.error(error);
    }
  };

  const handleDisconnect = async () => {
    const authTypes = await retieveAuthTypes();
    const datasourceServiceManager = new DatasourceService();
    const params = {
      authTag: authTypes.authTag,
      connectorInstanceId: id,
    };
    const response = await datasourceServiceManager.disconnectDatasource(params);
    if (response?.status === 204) {
      setAuth({ ...authConfigs, authStatus: '' });
    }
  };

  return (
    <div className={MAIN_CLASS}>
      <Formik
        initialValues={authConfigs}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <div>
            <form className={FORM_CLASS}>
              <div className={`${MAIN_CLASS}__heading-text`}>Authorization OAuth 2.0</div>
              <div className={ITEM_CLASS}>
                <label className={`${ITEM_CLASS}__label`}>Client ID</label>
                <Field
                  type="text"
                  name="clientId"
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  label=""
                  hasError={touched.clientId && errors.clientId ? true : false}
                  value={values.clientId}
                  errorMessage={<span>{errors.clientId}</span>}
                  onChange={(e: any) => {
                    handleChange(e);
                    setAuth({ ...authConfigs, clientId: e.target.value });
                  }}
                  onBlur={handleBlur}
                />
                <p className={`${ITEM_CLASS}__input-desc`}></p>
              </div>

              <div className={ITEM_CLASS}>
                <label className={`${ITEM_CLASS}__label`}>Client Secret</label>
                <Field
                  type="password"
                  name="clientSecret"
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  label=""
                  hasError={touched.clientSecret && errors.clientSecret ? true : false}
                  value={values.clientSecret}
                  errorMessage={<span>{errors.clientSecret}</span>}
                  onChange={(e: any) => {
                    handleChange(e);
                    setAuth({ ...authConfigs, clientSecret: e.target.value });
                  }}
                  onBlur={handleBlur}
                />
              </div>
              {authConfigs.authStatus !== 'connected' && (
                <FlightButton
                  className={`${ITEM_CLASS}__button`}
                  type="primary"
                  size="large"
                  label="Authorize"
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleAuthorize();
                  }}
                />
              )}
              {authConfigs.authStatus === 'connected' && (
                <div className={`${FORM_CLASS}__notify`}>
                  <div className={`${FORM_CLASS}__notify-message`}>
                    {getIcon('baselineCheck', null)}
                    <p className={`${FORM_CLASS}__notify-text`}> Connected</p>
                  </div>

                  <FlightButton
                    className={`${FORM_CLASS}__button`}
                    type="secondary"
                    size="medium"
                    label="Disconnect"
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleDisconnect();
                    }}
                  />
                </div>
              )}
              {(authConfigs.authStatus === 'revoked' || authConfigs.authStatus === 'failed') && (
                <div className={`${FORM_CLASS}__notify ${FORM_CLASS}__notify--revoked`}>
                  <p className={`${FORM_CLASS}__notify-message`}>
                    {getIcon('warning', null)} Token revoked, reauthorization required
                  </p>
                </div>
              )}
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Authorization;
