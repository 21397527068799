import React, { FunctionComponent, useState, useEffect } from 'react';
import { FlightButton } from '@flybits/webapp-design-system-react';
import SvgFlybitsLogo from '../assets/FlybitsLogo';
import { gsap } from 'gsap';
import '../styles/pages/VerifyAccount.scss';

const VerifyAccount: FunctionComponent<{}> = () => {
  const MAIN_CLASS = 'verify';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const ICON = `${MAIN_CLASS}__icon`;

  const [isLoading, setLoading] = useState(false);
  const [isVerified, showVerified] = useState(false);

  useEffect(() => {
    gsap.timeline().fromTo(
      `.verify__content__wrapper > *`,
      { y: -20, opacity: 0 },
      {
        y: 0,
        duration: 0.8,
        stagger: 0.125,
        opacity: 1,
      },
      '+=.4',
    );
  }, []);

  return (
    <div className={MAIN_CLASS}>
      <div className={CONTENT_CLASS}>
        <div className={`${CONTENT_CLASS}__wrapper`}>
          <SvgFlybitsLogo width={162} height={40} viewBox="0 0 104 30" {...{ className: `${MAIN_CLASS}__logo` }} />
          <div className={ICON}>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M74.9999 56.6667V30C74.9999 22.6367 69.0299 16.6667 61.6666 16.6667H26.6666C19.3033 16.6667 13.3333 22.6367 13.3333 30V56.6667H74.9999Z"
                fill="#2196F3"
              />
              <path
                d="M18.3333 55C18.3333 55.92 17.5867 56.6666 16.6667 56.6666H6.66667C5.74667 56.6666 5 55.92 5 55C5 54.08 5.74667 53.3333 6.66667 53.3333H16.6667C17.5867 53.3333 18.3333 54.08 18.3333 55Z"
                fill="#2196F3"
              />
              <path
                d="M26.6667 20C21.1517 20 16.6667 24.485 16.6667 30V53.3333H36.6667V30C36.6667 24.485 32.1818 20 26.6667 20Z"
                fill="#0D47A1"
              />
              <path d="M46.6667 6.66669H56.6667V13.3334H46.6667V6.66669Z" fill="#FF3D00" />
              <path d="M46.6667 10H50.0001V28.3333H46.6667V10Z" fill="#FF3D00" />
              <path
                d="M48.3333 26.6667C47.4493 26.6667 46.6014 27.0179 45.9763 27.643C45.3512 28.2681 45 29.116 45 30C45 30.8841 45.3512 31.7319 45.9763 32.357C46.6014 32.9822 47.4493 33.3334 48.3333 33.3334C49.2174 33.3334 50.0652 32.9822 50.6904 32.357C51.3155 31.7319 51.6667 30.8841 51.6667 30C51.6667 29.116 51.3155 28.2681 50.6904 27.643C50.0652 27.0179 49.2174 26.6667 48.3333 26.6667Z"
                fill="#FF3D00"
              />
              <path
                d="M48.3334 28.3333C47.8914 28.3333 47.4675 28.5089 47.1549 28.8215C46.8423 29.134 46.6667 29.558 46.6667 30C46.6667 30.442 46.8423 30.8659 47.1549 31.1785C47.4675 31.4911 47.8914 31.6666 48.3334 31.6666C48.7754 31.6666 49.1994 31.4911 49.5119 31.1785C49.8245 30.8659 50.0001 30.442 50.0001 30C50.0001 29.558 49.8245 29.134 49.5119 28.8215C49.1994 28.5089 48.7754 28.3333 48.3334 28.3333Z"
                fill="#DD2C00"
              />
              <path d="M41.6667 56.6667H50.0001V73.3334H41.6667V56.6667Z" fill="#FFCC80" />
              <path d="M41.6667 56.6667H50.0001V60H41.6667V56.6667Z" fill="#FFA726" />
              <path
                d="M21.6667 35V50C21.6667 51.7084 23.2917 53.3334 25.0001 53.3334H36.6667V31.6667H25.0001C23.2917 31.6667 21.6667 33.2917 21.6667 35Z"
                fill="#CFD8DC"
              />
              <path
                d="M21.6667 35V36.6667L36.6667 48.3333V45L22.0301 33.615C21.8051 34.05 21.6667 34.5183 21.6667 35Z"
                fill="#78909C"
              />
            </svg>
          </div>
          <div className={`${CONTENT_CLASS}__heading-text`}>Please Verify Account</div>
          <div className={`${CONTENT_CLASS}__description-text`}>
            We sent an email to <strong>username@email.com</strong> to verify your account. Click the link in that email
            to verify your account.
          </div>
          <FlightButton
            className={`${CONTENT_CLASS}__button`}
            type="primary"
            size="medium"
            label="Resend verification email"
            loading={isLoading}
            disabled={isLoading}
            onClick={() => {
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
                showVerified(true);
                setTimeout(() => {
                  showVerified(false);
                }, 6000);
              }, 2000);
              console.log('Trying again...');
            }}
          />
          {isVerified && (
            <div className={`${CONTENT_CLASS}__verification-sent`}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.0001 1.66669C5.40008 1.66669 1.66675 5.40002 1.66675 10C1.66675 14.6 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6001 1.66669 10.0001 1.66669ZM8.33342 14.1667L4.16675 10L5.34175 8.82502L8.33342 11.8084L14.6584 5.48335L15.8334 6.66669L8.33342 14.1667Z"
                  fill="#00AB50"
                />
              </svg>
              Verification email sent!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyAccount;
