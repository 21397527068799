import BaseService from './base.service';
import { CreateOrganization } from '../model/organizations/organizations';

interface GetOrganization {
  limit: number;
}

export default class OrganizationService extends BaseService {
  CURRENT_PATH: string;
  constructor() {
    super();
    this.CURRENT_PATH = '/kernel/organizations';
  }

  async getOrganizations(params: GetOrganization) {
    return this.request('GET', this.CURRENT_PATH, params);
  }

  async getOrganization(organizationId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId);
  }

  async createOrganization(data: CreateOrganization) {
    return this.request('POST', this.CURRENT_PATH, data);
  }
}
