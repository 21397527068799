import React, { FunctionComponent, useState, useEffect } from 'react';
import DeveloperPortal from '../assets/developer-portal-application-logo.svg';
import Nestor from '../assets/nestor-application-logo.svg';
import ExperienceStudio from '../assets/experience-studio-application-logo.svg';

import ApplicationCard from '../components/ApplicationCard/ApplicationCard';
import '../styles/pages/Applications.scss';
import { Redirect } from 'react-router-dom';
import ProjectService from '../services/project.service';
import AuthService from '../services/authentication.service';
import { getAuthToken, getStoredProjectId } from '../helpers/auth';

const Applications: FunctionComponent<{}> = () => {
  const MAIN_CLASS = 'Applications';
  const APPLICATIONS_LIST_CLASS = `${MAIN_CLASS}__list`;
  const APPLICATIONS_LIST_HEADER_CLASS = `${APPLICATIONS_LIST_CLASS}__header`;
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [devPortalUrl, setDeveloperPortalUrl] = useState('');
  const [experienceStudioUrl, setExperienceStudioUrl] = useState('');
  const [nestorUrl, setNestorUrl] = useState('');

  useEffect(() => {
    const fetchProjectsAndSignedInUser = async () => {
      const token = await getAuthToken();
      const projectId = await getStoredProjectId();
      if (!token || !projectId) {
        setIsAuthenticated(false);
        return;
      }
      const projectServiceManager = new ProjectService();
      const authServiceManager = new AuthService();
      try {
        const projectResponseObject = await projectServiceManager.getProject(projectId);
        const projectObject = {
          id: projectResponseObject.data.id,
          name: projectResponseObject.data.Name,
          subDomain: projectResponseObject.data.Subdomain,
          creatorID: projectResponseObject.data.CreatorID,
          tierID: projectResponseObject.data.TierID,
          level: projectResponseObject.data.level ? projectResponseObject.data.level : 0,
          isActive: projectResponseObject.data.IsActive,
        };
        const projectTokenResponse = await authServiceManager.getProjectToken(projectId);
        if (
          projectTokenResponse &&
          projectTokenResponse.headers &&
          projectTokenResponse.headers['x-authorization'] &&
          projectObject &&
          projectObject.subDomain
        ) {
          setExperienceStudioUrl(
            `https://${projectObject.subDomain}/#/authenticate?sourceRouteName=&token=${projectTokenResponse.headers['x-authorization']}&userId=`,
          );
          setDeveloperPortalUrl(
            `${process.env.REACT_APP_API_DEV_PORTAL_URL}/#/authenticate?sourceRouteName=&token=${projectTokenResponse.headers['x-authorization']}&userId=`,
          );
          setNestorUrl(
            `https://dev-www.nestor.flybits.com/authorize/?environment=x-development&token=${projectTokenResponse.headers['x-authorization']}`,
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProjectsAndSignedInUser();
  }, []);

  return (
    <div className={MAIN_CLASS}>
      <div className={APPLICATIONS_LIST_CLASS}>
        <div className={APPLICATIONS_LIST_HEADER_CLASS}>
          <h1>Applications</h1>
        </div>
        {!isAuthenticated && <Redirect to="signIn" />}
        <ApplicationCard
          svg={ExperienceStudio}
          url={experienceStudioUrl}
          name="Experience Studio"
          classSelector="experience-studio"
        />
        <ApplicationCard svg={DeveloperPortal} url={devPortalUrl} name="Developer Portal" classSelector="dev-portal" />

        <ApplicationCard svg={Nestor} url={nestorUrl} name="Nestor" classSelector="nestor" />
      </div>
    </div>
  );
};

export default Applications;
