
import { CtxAttrOptions } from '../model/datasources/datasources';
import { ContextPlugin } from '../components/AttributeBuilder/contextPlugins';

export  const getCtxAttrCardProps = (options: CtxAttrOptions) => {
  return {
    name: options.name,
    selected: options.selected,
    uid: options.uid,
    tableRowIdx: options.tableRowIdx,
    tableCellIdx: options.tableCellIdx,
    removeCtxAttribute: options.removeCtxAttribute,
  };
};

export const serializeDefinedConfigData = (values:any, definedConfigSchema: any[]): any => {
  const definedConfigs: any = {};

  for (const config of definedConfigSchema) {
    const mappings: any = {};
    const fields = config.fields;

    for (const field of fields) {
      mappings[field.name] = {
        configKey: field.name,
        configVal: config.heading?.isOutput ? field.ctxAttributeName : values[field.name],
        dataType: field.dataType,
      };

      definedConfigs[config.heading?.name] = {
        headingName: config.heading?.name,
        headingViewName: config.heading?.viewName,
        isOutput: config.heading?.isOutput,
        mappings: mappings,
      };
    }
  }

  return definedConfigs;
};


export const serializePluginAttributes = (plugin: ContextPlugin[], definedConfigSchema: any[], dynamicConfigTableData: any[]):ContextPlugin[] => {
  const attrNames = [];

  for (const data of dynamicConfigTableData) {
    attrNames.push(data.ctxAttributeName);
  }

  for (const schema of definedConfigSchema) {
    if (schema.heading.isOutput) {
      for (const field of schema.fields) {
        attrNames.push(field.ctxAttributeName);
      }
    }
  }

  for (const key in plugin[0].values) {
    if (attrNames.includes(key)) {
      const arr = plugin[0].values[key].uid.split('.');

      if (arr[arr.length -1] !== key) {
        arr.push(key);
        plugin[0].values[key].uid = arr.join('.')
      }

    } else {
      delete plugin[0].values[key];
    }
  }

  plugin[0].userScope = true; //TODO: it should be set on the component level

  const newPlugin = { ...plugin[0]};
  plugin = [newPlugin];

  return [...plugin];
};

export const serializeDynamicConfigData = (
  values: any,
  dynamicConfigSchema: any[],
  dynamicConfigTableData: any[]): any => {

  const dynamicConfigs: any = {};
  const heading: any = {};

  heading['headingName'] = dynamicConfigSchema[0]?.heading.name;
  heading['headingViewName'] = dynamicConfigSchema[0]?.heading.viewName;
  heading['isOutput'] = dynamicConfigSchema[0]?.heading?.isOutput;
  heading['dynamicFields'] = [];

  const obj = JSON.parse(JSON.stringify(values?.dynamicConfigFormData[0]));
  let keys = Object.keys(obj);

  dynamicConfigTableData.forEach((item: any, index: number) => {
    const newObj = { ...obj};
      for (let key in item) {
        if (keys.includes(key)) {
          if (key !== 'outputFieldName') {
            if (values.dynamicConfigFormData[index+1]) {
              newObj[key] = values.dynamicConfigFormData[index+1][key] || '';
            }
          } else {
            newObj[key] = item.ctxAttributeName || '';
          }
        }
      }

      const vals:string[] = Object.values(newObj);
      const filteredItems = vals.filter((item:string) => {
        return item.length > 0;
      });

      if (filteredItems.length) {
        heading['dynamicFields'].push(newObj);
      }
  });

  dynamicConfigs['heading'] = heading;

  return dynamicConfigs;
};

export const sanitizeName = (name: string) => {
  let newName = name.replace(/[^A-z 0-9 \- \s]|\^/g, '');
  newName = newName.replace(/\s/g, '-');
  newName = newName.replace(/-+/g, '-');
  if (newName.charAt(newName.length - 1) === '-') {
    newName = newName.substring(0, newName.length - 2);
  }
  return newName;
};