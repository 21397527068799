import React from 'react';
import DatasourcesManagementPage from '../../pages/Datasources/DatasourcesManagementPage';
import PasswordChange from '../../pages/ChangePassword';
import PasswordReset from '../../pages/PasswordReset';
import NotFound from '../../pages/NotFound';
import Projects from '../../pages/Projects';
import SignIn from '../../pages/SignIn';
import SignUp from '../../pages/SignUp';
import Applications from '../../pages/Applications';
import TokenClear from '../../pages/authentication/token/Clear';
import TokenGet from '../../pages/authentication/token/Get';
import TokenValidate from '../../pages/authentication/token/Validate';
import NavBar from '../../components/NavBar/NavBar';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import DatasourcesCreationPage from '../../pages/Datasources/DatasourcesCreationPage';
import DatasourcesShare from '../../pages/Datasources/DatasourcesShare';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';

const SSORoutes = () => (
  <Router>
    <Switch>
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/authentication/token/clear" component={TokenClear} />
      <Route path="/authentication/token/get" component={TokenGet} />
      <Route path="/authentication/token/validate" component={TokenValidate} />
      <Route path="/password/change" component={PasswordChange} />
      <Route path="/password/reset" component={PasswordReset} />
      <Redirect from="/" to="/signin" exact={true} />
      <NavBar>
        <Switch>
          <PrivateRoute exact={true} path="/projects" component={Projects} />
          <PrivateRoute exact={true} path="/projects/:id/applications" component={Applications} />
          <PrivateRoute exact={true} path="/datasources" component={DatasourcesManagementPage} />
          <PrivateRoute exact={true} path="/datasources/management" component={DatasourcesManagementPage} />
          <PrivateRoute exact={true} path="/datasources/create" component={DatasourcesCreationPage} />
          <PrivateRoute exact={true} path="/datasources/create/:id" component={DatasourcesShare} />
          <PrivateRoute exact={true} path="/datasources/edit/:id" component={DatasourcesShare} />
          <Route component={NotFound} />
        </Switch>
      </NavBar>
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default SSORoutes;
