import React, { FunctionComponent, useState, useEffect } from 'react';
import SvgFlybitsLogo from '../assets/FlybitsLogo';
import BannerImage from '../components/BannerImage/BannerImage';
import { FlightButton, FlightSnackbar, FlightTextInput, FlightModal } from '@flybits/webapp-design-system-react';
import { Link } from 'react-router-dom';
import '../styles/pages/PasswordReset.scss';
import { EMAIL_VALIDATION, EMAIL_REQUIRED, GENERIC_INTERNAL_ERROR } from '../constants/errors';
import AuthService from '../services/authentication.service';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import { gsap } from 'gsap';

const PasswordReset: FunctionComponent<{}> = () => {
  useEffect(() => {
    gsap.timeline().fromTo(
      `.PasswordReset__form > *`,
      { y: -20, opacity: 0 },
      {
        y: 0,
        duration: 0.8,
        stagger: 0.125,
        opacity: 1,
      },
      '+=.4',
    );
  }, []);
  const MAIN_CLASS = 'PasswordReset';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const LINK_CLASS = `${ITEM_CLASS}__link`;

  const initialValues = { email: '', password: '' };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(EMAIL_REQUIRED).email(EMAIL_VALIDATION),
  });

  const [isPasswordReset, setPasswordReset] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const initPasswordReset = async (email: string) => {
    setLoading(true);
    const authServiceManger = new AuthService();
    const ResetPasswordRequestBody = {
      email: email,
    };
    try {
      const response = await authServiceManger.resetPassword(ResetPasswordRequestBody);
      if (response && response.status === 204) {
        setPasswordReset(true);
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.exceptionMessage
      ) {
        setErrorMessage(error.response.data.error.exceptionMessage);
        setError(true);
      } else {
        setErrorMessage(GENERIC_INTERNAL_ERROR);
        setError(true);
      }
      console.error(error);
    }
    setLoading(false);
  };

  const togglePasswordReset = () => {
    setPasswordReset(false);
    setLoading(false);
  };

  const toggleModal = () => {
    setError(false);
  };

  return (
    <div className={MAIN_CLASS}>
      <FlightModal
        isVisible={error}
        toggleModalShown={toggleModal}
        scrollable={false}
        size="small"
        warning={true}
        className="custom-modal-class"
        header={<span>Unable to perform action</span>}
        content={<span>{errorMessage}</span>}
      />
      <BannerImage />
      <div className={`${MAIN_CLASS}__form-container`}>
        <FlightSnackbar
          className={`${MAIN_CLASS}__reset-snackbar`}
          isFloating={false}
          isVisible={isPasswordReset}
          content="Email sent."
          type="success"
          isAutoDismiss={false}
          actionName=""
          handleClose={togglePasswordReset}
        />
        <SvgFlybitsLogo width={104} height={30} viewBox="0 0 104 30" {...{ className: `${MAIN_CLASS}__logo` }} />
        <div className={`${MAIN_CLASS}__heading-text`}>Forgot your password?</div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            initPasswordReset(values.email);
            resetForm();
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form className={FORM_CLASS}>
              <div className={ITEM_CLASS}>
                <Field
                  type="text"
                  name="email"
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  label="Email"
                  hasError={touched.email && errors.email ? true : false}
                  value={values.email}
                  errorMessage={<span>{errors.email}</span>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <FlightButton
                className={`${ITEM_CLASS}__button`}
                type="primary"
                size="large"
                label="Send password reset link"
                loading={isLoading}
                disabled={isLoading || !touched.email || !!errors.email}
                onClick={handleSubmit}
              />
            </form>
          )}
        </Formik>
        <div className={`${ITEM_CLASS}__signin`}>
          <Link to="../signin" className={LINK_CLASS}>
            Back to sign in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
