import React, { FunctionComponent, useState, useEffect } from 'react';
import { setAuthToken } from '../../../helpers/auth';
import AuthService from '../../../services/authentication.service';
import { Redirect } from 'react-router';
import { FlightModal, FlightButton } from '@flybits/webapp-design-system-react';

const Clear: FunctionComponent<{}> = () => {
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const authenticateUser = async () => {
    const authServiceManager = new AuthService();
    try {
      const authenticationResponse = await authServiceManager.getSignedInUser();
      if (authenticationResponse && authenticationResponse.status === 200) {
        return true;
      } else return false;
    } catch (error) {
      if ((error && error.response && error.response.status >= 400) || error.response.status <= 599) {
        setErrorMessage(`Failed to authenticate user: ${error.message}`);
        setError(true);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const initiateValidation = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      try {
        if (queryParams.has('hash')) {
          const hash = queryParams.get('hash');
          if (hash) {
            const decodedHash = window.atob(hash);
            const decodedJson = JSON.parse(decodedHash);
            const redirectUrl = decodedJson['redirectOnSuccess'];
            const token = decodedJson['x-authorization'];
            if (decodedJson && token && redirectUrl) {
              await setAuthToken(decodedJson['x-authorization']);
              const shouldRedirect = await authenticateUser();
              if (shouldRedirect) {
                window.location.replace(`https://${redirectUrl}?hash=${hash}`);
                return;
              }
            }
          }
        } else {
          setErrorMessage('No hash present in query parameters.');
          setError(true);
        }
      } catch (error) {
        setErrorMessage('Hash could not be decoded.');
        setError(true);
        console.error(error);
      }
    };
    initiateValidation();
  }, []);

  const toggleModal = () => {
    setError(false);
    setRedirect(true);
  };

  return (
    <div>
      <FlightModal
        isVisible={error}
        toggleModalShown={toggleModal}
        scrollable={false}
        size="small"
        warning={false}
        className="custom-modal-class"
        header={<span>Authentication Failed</span>}
        content={<span>{errorMessage}</span>}
        footer={
          <div className="modal-footer">
            <FlightButton type="primary" label="Back to sign in" onClick={toggleModal} />
          </div>
        }
      />
      {redirect && <Redirect to="/authentication/token/clear" />}
    </div>
  );
};

export default Clear;
