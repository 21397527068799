import React, { FunctionComponent } from 'react';
import { FlightButton } from '@flybits/webapp-design-system-react';
// import { Redirect } from 'react-router-dom';
import Error404Image from '../components/Error404Image/Error404Image';
import '../styles/pages/NotFound.scss';

const NotFound: FunctionComponent<{}> = () => {
  const MAIN_CLASS = 'not_found';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const FIGURE_CLASS = `${MAIN_CLASS}__figure`;
  return (
    <div className={MAIN_CLASS}>
      <div className={CONTENT_CLASS}>
        <div className={`${CONTENT_CLASS}__wrapper`}>
          <div className={`${CONTENT_CLASS}__heading-text`}>Something went wrong.</div>
          <div className={`${CONTENT_CLASS}__description-text`}>
            We encountered an error trying to process your request. You can retry now, refresh the page or come back
            later.
          </div>
          <FlightButton
            className={`${CONTENT_CLASS}__button`}
            type="primary"
            size="medium"
            label="Retry"
            onClick={() => console.log('Trying again...')}
          />
        </div>
      </div>
      <div className={FIGURE_CLASS}>
        <Error404Image />
      </div>
    </div>
  );
};

export default NotFound;
