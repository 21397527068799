import BaseService from './base.service';
import {
  SignInParameters,
  SignUpParameters,
  PasswordResetParameters,
  ChangePasswordParameters,
  SendConfirmEmailParameters,
} from '../model/auth/auth';

export default class AuthService extends BaseService {
  CURRENT_PATH: string;
  PRIMARY_OPTIONAL_RESOURCE: string;
  SECONDARY_OPTIONAL_RESOURCE: string;
  SIGN_IN_RESOURCE: string;
  SIGN_OUT_RESOURCE: string;
  PASSWORD_RESET_RESOURCE: string;
  PASSWORD_CHANGE_RESOURCE: string;
  EMAIL_CONFIRMATION_RESOURCE: string;

  constructor() {
    super();
    this.CURRENT_PATH = 'sso/auth/';
    this.PRIMARY_OPTIONAL_RESOURCE = 'me/';
    this.SECONDARY_OPTIONAL_RESOURCE = 'project/';

    this.SIGN_IN_RESOURCE = 'authenticate';
    this.SIGN_OUT_RESOURCE = 'logout';
    this.PASSWORD_RESET_RESOURCE = 'sendResetPasswordEmail';
    this.PASSWORD_CHANGE_RESOURCE = 'resetPassword';
    this.EMAIL_CONFIRMATION_RESOURCE = 'sendConfirmEmail';
  }

  async getSignedInUser() {
    return this.request('GET', this.CURRENT_PATH + this.PRIMARY_OPTIONAL_RESOURCE);
  }

  async getProjectToken(projectId: string) {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_OPTIONAL_RESOURCE + `?projectId=${projectId}`);
  }

  async getOrganizationToken(organizationId: string) {
    return this.request(
      'GET',
      this.CURRENT_PATH + this.SECONDARY_OPTIONAL_RESOURCE + `?organizationId=${organizationId}`,
    );
  }

  async signInUser(params: SignInParameters) {
    return this.request('POST', this.CURRENT_PATH + this.SIGN_IN_RESOURCE, params);
  }

  async signUpUser(params: SignUpParameters) {
    return this.request('POST', this.CURRENT_PATH + this.SIGN_IN_RESOURCE, params);
  }

  async signOutUser() {
    return this.request('POST', this.CURRENT_PATH + this.SIGN_OUT_RESOURCE);
  }

  async resetPassword(params: PasswordResetParameters) {
    return this.request('POST', this.CURRENT_PATH + this.PASSWORD_RESET_RESOURCE, params);
  }

  async sendConfirmationEmail(params: SendConfirmEmailParameters) {
    return this.request('POST', this.CURRENT_PATH + this.EMAIL_CONFIRMATION_RESOURCE, params);
  }

  async changePassword(params: ChangePasswordParameters) {
    return this.request('POST', this.CURRENT_PATH + this.PASSWORD_CHANGE_RESOURCE, params);
  }

  
}
