import BaseService from './base.service';
import { CreateProject } from '../model/projects/projects';

interface GetProjects {
  limit: number;
}

export default class ProjectService extends BaseService {
  CURRENT_PATH: string;
  constructor() {
    super();
    this.CURRENT_PATH = '/kernel/projects';
  }

  async getProjects(params: GetProjects) {
    return this.request('GET', this.CURRENT_PATH, params);
  }

  async getProject(projectId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + projectId);
  }

  async createProject(data: CreateProject) {
    return this.request('POST', this.CURRENT_PATH, data);
  }
}
