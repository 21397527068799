import localforage from 'localforage';

export async function setAuthToken(token: string): Promise<string> {
  await localforage.setItem('token', token);
  return 'success';
}

export async function deleteAuthToken(): Promise<string> {
  await localforage.removeItem(`token`);
  return 'success';
}

export async function getAuthToken(): Promise<string> {
  const authToken: string = (await localforage.getItem('token')) || '';
  return authToken;
}

export async function setStoredEmail(email: string): Promise<string> {
  await localforage.setItem('email', email);
  return 'success';
}

export async function deleteStoredEmail(): Promise<string> {
  await localforage.removeItem(`email`);
  return 'success';
}

export async function getStoredEmail(): Promise<string> {
  const email: string = (await localforage.getItem('email')) || '';
  return email;
}

export async function setStoredPassword(email: string): Promise<string> {
  await localforage.setItem('password', email);
  return 'success';
}

export async function deleteStoredPassword(): Promise<string> {
  await localforage.removeItem(`password`);
  return 'success';
}

export async function getStoredPassword(): Promise<string> {
  const password: string = (await localforage.getItem('password')) || '';
  return password;
}

export async function setStoredProjectId(projectId: string): Promise<string> {
  await localforage.setItem('projectId', projectId);
  return 'success';
}

export async function getStoredProjectId(): Promise<string> {
  const projectId: string = (await localforage.getItem('projectId')) || '';
  return projectId;
}

export async function deleteStoredProjectId(): Promise<string> {
  await localforage.removeItem(`projectId`);
  return 'success';
}

export async function setStoredOrganizationId(organizationId: string): Promise<string> {
  await localforage.setItem('organizationId', organizationId);
  return 'success';
}

export async function getStoredOrganizationId(): Promise<string> {
  const organizationId: string = (await localforage.getItem('organizationId')) || '';
  return organizationId;
}

export async function deleteStoredOrganizationId(): Promise<string> {
  await localforage.removeItem(`organizationId`);
  return 'success';
}

export function validateEmail(email: string): boolean {
  return /^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email); //eslint-disable-line no-useless-escape
}

export function validateName(name: string): boolean {
  return /^[a-zA-Z]+$/.test(name);
}

export function validatePassword(password: string): boolean {
  return /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/.test(password);
}

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/;

export const nameRegex = /^[a-zA-Z]+$/;
