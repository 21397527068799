import React, { FunctionComponent, useState } from 'react';
import Jdenticon from '../Jdenticon';
import { FlightCard, FlightTooltip } from '@flybits/webapp-design-system-react';
import './ProjectListItem.scss';
import { setStoredProjectId } from '../../helpers/auth';
import { Redirect, useRouteMatch } from 'react-router';
import AuthService from '../../services/authentication.service';
interface Props {
  id: string;
  url: string;
  name: string;
  isActive: boolean;
  redirectExperienceStudio: boolean;
}

const ProjectListItem: FunctionComponent<Props> = (props: Props) => {
  const { id, url, name, isActive, redirectExperienceStudio } = props;
  const DEFAULT_CLASS = 'projectListItem';
  const WRAPPER_CLASS = `${DEFAULT_CLASS}__wrapper`;
  const BORDER_CLASS = `${DEFAULT_CLASS}__border`;
  const ICON_CLASS = `${DEFAULT_CLASS}__project-icon`;
  const INACTIVE_CLASS = `${DEFAULT_CLASS}__inactive`;
  const [redirectToApplications, setRedirectToApplications] = useState(false);
  const { path } = useRouteMatch();

  const handleOnClick = async () => {
    if (!isActive) return;
    try {
      if (redirectExperienceStudio) {
        const authServiceManager = new AuthService();
        const projectTokenResponse = await authServiceManager.getProjectToken(id);
        const token = projectTokenResponse.headers['x-authorization'];
        if (token) {
          window.location.replace(`https://${props.url}/#/authenticate?sourceRouteName=&token=${token}&userId=`);
        }
      } else {
        setStoredProjectId(id);
        setRedirectToApplications(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={WRAPPER_CLASS} key={id} onClick={handleOnClick}>
      {redirectToApplications && <Redirect to={path + '/' + id + '/applications'} />}
      <FlightTooltip direction="bottom" description={isActive ? url : 'Inactive'}>
        <FlightCard
          className={`${BORDER_CLASS} ${!isActive ? INACTIVE_CLASS : ''}`}
          header={name}
          body={<Jdenticon size="60" value={name} className={ICON_CLASS} />}
          footer={isActive ? url : 'Inactive'}
        />
      </FlightTooltip>
    </div>
  );
};

export default ProjectListItem;
