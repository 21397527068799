import BaseService from './base.service';

interface GetConnectors {
  limit?: number;
  id?: string;
  type?: string;
}

interface GetConnector {
  id: string;
  type?: string;
}

export default class ConnectorsService extends BaseService {
  CURRENT_PATH: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'context/connector-manager/connector';
  }

  async getConnectors(params: GetConnectors) {
    return this.request('GET', this.CURRENT_PATH, params);
  }

   async getConnector(params: GetConnector) {
    return this.request('GET', this.CURRENT_PATH, params);
  }
}
