import React, { FunctionComponent, useState, ReactElement, useEffect } from 'react';
import { FlightNav, FlightButton } from '@flybits/webapp-design-system-react';
import SvgFlybitsLogo from '../../assets/FlybitsLogo';
import AuthService from '../../services/authentication.service';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import bulletTrain from 'bullet-train-client';
import { DATASOURCES_ENABLED } from '../../constants/featureFlags';
import './NavBar.scss';
import { deleteAuthToken } from '../../helpers/auth';

interface Props {
  children: ReactElement;
}
const NavBar: FunctionComponent<Props> = (props: Props) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isDatasourcesLinkVisible, setIsDatasourcesLinkVisible] = useState(false);
  const [user, SetUser] = useState({ id: '', firstName: '', lastName: '', email: '' });
  const history = useHistory();

  useEffect(() => {
    bulletTrain.init({
      environmentID: `${process.env.REACT_APP_BULLET_TRAIN_ENVIRONMENT_ID}`,
      onChange: () => {
        if (bulletTrain.hasFeature(DATASOURCES_ENABLED)) {
          setIsDatasourcesLinkVisible(true);
        }
      },
    });
    const fetchUser = async () => {
      const authServiceManager = new AuthService();
      try {
        const signInUserResponseObject = await authServiceManager.getSignedInUser();
        if (signInUserResponseObject) {
          SetUser({
            id: signInUserResponseObject.data.id,
            email: signInUserResponseObject.data.email,
            firstName: signInUserResponseObject.data.firstName,
            lastName: signInUserResponseObject.data.lastName,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, []);

  const initSignOut = () => {
    const authServiceManager = new AuthService();
    try {
      authServiceManager.signOutUser();
      setIsAuthenticated(false);
      deleteAuthToken();
    } catch (error) {
      console.error(error);
    }
  };

  const handleRedirectToMyProjects = () => {
    history.push('/projects');
  };

  const handleRedirectToMyDatasources = () => {
    history.push('/datasources/management');
  };

  return (
    <FlightNav
      user={user}
      handleSignOut={initSignOut}
      hasSidebar={false}
      actionables={
        <div className="datasources-link">
          <FlightButton
            ariaLabel=""
            className="atag"
            label="My Projects"
            size="large"
            onClick={() => {
              handleRedirectToMyProjects();
            }}
            theme="link"
          />
          {isDatasourcesLinkVisible && (
            <FlightButton
              ariaLabel=""
              label="My Datasources"
              className="atag"
              size="large"
              onClick={() => {
                handleRedirectToMyDatasources();
              }}
              theme="link"
            />
          )}
        </div>
      }
      logo={
        <div className="main-logo-container">
          <SvgFlybitsLogo {...{ className: '' }} />
        </div>
      }
    >
      <div>
        {!isAuthenticated && <Redirect to="/signIn" />}
        {children}
      </div>
    </FlightNav>
  );
};
export default NavBar;
