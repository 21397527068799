export const inputTypeOptions = [
  {
    key: 'freeform',
    name: 'Freeform',
  },
  {
    key: 'dropdown',
    name: 'Dropdown',
  },
];
