import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { getAuthToken } from '../../helpers/auth';

const PrivateRoute = (routeProps: RouteProps) => {
  const history = useHistory();

  useEffect(() => {
    const fn = async () => {
      const _isAuthenticated = await getAuthToken();
      if (!_isAuthenticated) {
        history.push('/signin');
        return null;
      }
    };
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeProps.path]);

  return <Route {...routeProps} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

export default PrivateRoute;
