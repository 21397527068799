import React from 'react';
import Applications from '../../pages/Applications';
import NotFound from '../../pages/NotFound';
import PasswordChange from '../../pages/ChangePassword';
import PasswordReset from '../../pages/PasswordReset';
import Organizations from '../../pages/Organizations';
import Projects from '../../pages/Projects';
import SignIn from '../../pages/SignIn';
import SignUp from '../../pages/SignUp';
import VerifyAccount from '../../pages/VerifyAccount';
import TokenClear from '../../pages/authentication/token/Clear';
import TokenGet from '../../pages/authentication/token/Get';
import TokenValidate from '../../pages/authentication/token/Validate';
import NavBar from '../../components/NavBar/NavBar';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';

const ControlTowerRoutes = () => (
  <Router>
    <Switch>
      <Route path="/ct/signin" component={SignIn} />
      <Route path="/ct/signup" component={SignUp} />
      <Route path="/ct/verify" component={VerifyAccount} />
      <Route path="/ct/authentication/token/clear" component={TokenClear} />
      <Route path="/ct/authentication/token/get" component={TokenGet} />
      <Route path="/ct/authentication/token/validate" component={TokenValidate} />
      <Route path="/ct/password/change" component={PasswordChange} />
      <Route path="/ct/password/reset" component={PasswordReset} />
      <Redirect from="/ct/" to="/ct/signin" exact={true} />
      <NavBar>
        <Switch>
          <PrivateRoute exact={true} path="/ct/organizations" component={Organizations} />
          <PrivateRoute exact={true} path="/ct/projects" component={Projects} />
          <PrivateRoute exact={true} path="/ct/projects/:id/applications" component={Applications} />
          <Route component={NotFound} />
        </Switch>
      </NavBar>
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default ControlTowerRoutes;
