import BaseService from './base.service';
import { ProjectCustomSettings } from '../model/projectCustomSettings/projectCustomSettings';

export default class SettingsService extends BaseService {
  CURRENT_PATH: string;
  CURRENT_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = '/sso/settings/';
    this.CURRENT_RESOURCE = 'configs';
  }

  async createProjectCustomContentTemplateSettings(data: ProjectCustomSettings) {
    return this.request('POST', this.CURRENT_PATH + this.CURRENT_RESOURCE, data);
  }
}
