export const scopedOptions = [
  {
    key: 'user',
    name: 'User',
  },
  {
    key: 'project',
    name: 'Project',
  },
];
