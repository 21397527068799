import React, { InputHTMLAttributes, ChangeEvent, useState, useEffect } from 'react';
import './FormElements.scss';
import {
  getIn,
  Field,
  useFormikContext,
} from 'formik';

import {
  FlightTextInput,
  FlightCheckbox,
  FlightRadioButton,
  FlightSelect,
  FlightTextArea } from '@flybits/webapp-design-system-react';

interface Props {}

const MAIN_CLASS = 'FormElements';
const ITEM_CLASS = `${MAIN_CLASS}__item`;
const RADIO_GROUP_ICON_CLASS = `${ITEM_CLASS}__radio__group__icon`;

interface FieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  description: string;
  label: string;
  isTable?: boolean;
  inputOpts?: any[];
  ctxAttributeCard?: any;
  ctxAttributeName?: string;
  value?: string;
}

export function TextField(props: FieldProps) {
  const { name, label, required,  description, isTable, value,  ctxAttributeCard, ctxAttributeName, ...rest} = props;

  type FormValues = {
    [name: string]: string;
  };

  // eslint-disable-next-line
  const { values, handleChange, setFieldValue } = useFormikContext<FormValues>();

  return (
    <div className={MAIN_CLASS}>
      { !isTable ?  <>
        { label && <label htmlFor={name} className={`${ITEM_CLASS}__label`}>{label} </label>} </>: ''
      }

    <Field
        className={`${ITEM_CLASS}__input`}
        type="text"
        as={FlightTextInput}
        name={name}
        value={getIn(values, name)}
        onChange={(e: ChangeEvent<HTMLButtonElement>) => {
          setFieldValue(name, e.target.value);
        }}
        id={name}
        width="100%"
        {...rest}
      />
      { !isTable ?  (description && <p className={`${ITEM_CLASS}__input-desc`}>{description}</p>): <></> }
    </div>
  )
}

export function PasswordField(props: FieldProps) {
  const { name, label, description, isTable,  ...rest} = props;
  return (
    <div className={MAIN_CLASS}>
      { !isTable ? <>
        { label && <label htmlFor={name} className={`${ITEM_CLASS}__label`}>{label}</label>} </>: ''
      }
      <Field
          className={`${ITEM_CLASS}__input`}
          type="password"
          as={FlightTextInput}
          name={name}
          id={name}
          {...rest}
        />
        { !isTable ?  description && <p className={`${ITEM_CLASS}__input-desc`}>{description}</p>: ''}
    </div>
  )
}

export function CheckboxField(props: FieldProps) {
  const { name, label, description, isTable,  ...rest} = props;
  return (
    <div className={MAIN_CLASS}>
      { !isTable ? <>
        { label && <label htmlFor={name} className={`${ITEM_CLASS}__label`}>{label}</label>} </> : ''
      }
      <Field
          className="form-control"
          type="checkbox"
          as={FlightCheckbox}
          name={name}
          id={name}
          {...rest}
        />
        { !isTable ?  description && <p className={`${ITEM_CLASS}__input-desc`}>{description}</p>: ''}
    </div>
  )
}

export function TextAreaField(props: FieldProps) {
  const { name, label, description, isTable,  ...rest} = props;
  return (
    <div className={MAIN_CLASS}>
      { !isTable ?  <>
        { label && <label htmlFor={name} className={`${ITEM_CLASS}__label`}>{label}</label>} </> : ''
      }
      <Field
          className={`${ITEM_CLASS}__input`}
          as={FlightTextArea}
          name={name}
          id={name}
          {...rest}
        />
      { !isTable ?  description && <p className={`${ITEM_CLASS}__input-desc`}>{description}</p>: ''}
    </div>
  )
}

export function RadioFields(props: FieldProps) {
  const { name, label, description, isTable, inputOpts, ctxAttributeCard } = props;
  const [configVal, setConfigVal] = useState<string>('');
  type FormValues = {
    [name: string]: string,
  }

  const { values, setFieldValue } = useFormikContext<FormValues>();

  useEffect(() => {
    if (!values[name] && ctxAttributeCard && ctxAttributeCard.length) {
      setConfigVal(ctxAttributeCard);
      setFieldValue(name, ctxAttributeCard);
    } else if (values[name]) {
      setConfigVal(values[name]);
    }else {
      setConfigVal(inputOpts?.[0]); //set default selection
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, inputOpts, ctxAttributeCard, values]);

  return (
    <div className={MAIN_CLASS}>
      { !isTable ? <>
        { label && <label htmlFor={name} className={`${ITEM_CLASS}__label`}>{label}</label>} </> : ''
      } 

      <div className={`${ITEM_CLASS}__radio-group`}>
        {(inputOpts || []).map((opt, index) => {
          return (
            <span key={index}>
              <Field
                type="radio"
                name={name}
                checked={configVal === opt ? true: false }
                id={name}
                as={FlightRadioButton}
                className={RADIO_GROUP_ICON_CLASS}
                label={opt}
                value={opt}
                onSelect={() => {
                  if (name) {
                    values[name] = opt;
                  }
                  setConfigVal(opt);
                }}
              />
            </span>);
        })}
      </div>
      { !isTable ?  description && <p className={`${ITEM_CLASS}__input-desc`}>{description}</p>: ''}
    </div>
  )
}

export function SelectField(props: FieldProps) {
  const { name, label, description, isTable, inputOpts } = props;
  const options = (inputOpts || []).map(opt => { return {key: opt, name: opt}});

  type FormValues = {
    [name: string]: string,
  }
  // eslint-disable-next-line
  const [selected, setSelected] = useState<any>({ key: '', name: ''});
  const { values, setFieldValue } = useFormikContext<FormValues>();

  useEffect(() => {
    if (!selected.key.length) {
      const val = getIn(values, name);
      setSelected({...selected, key: name, name: val});
    }
  }, [values, name, selected])

  return (
    <div className={MAIN_CLASS}>
      { !isTable ?  <>
        { label && <label htmlFor={name} className={`${ITEM_CLASS}__label`}>{label}</label>} </> : ''
      }
      <Field
        as={FlightSelect}
        id={name}
        name={name}
        selected={selected}
        options={options}
        handleOptionClick={(opt:any) => {
          setFieldValue(name, opt.key);
          setSelected(opt);
        }}
      />
      { !isTable ?  description && <p className={`${ITEM_CLASS}__input-desc`}>{description}</p>: ''}
    </div>
  )
}