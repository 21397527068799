/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { getAuthToken, setAuthToken } from '../helpers/auth';
const AXIOS_SERVICE = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_URL,
  timeout: 100000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const tokenNeeded = (url: string) => {
  return (
    url.indexOf('?projectId') >= 0 ||
    url.indexOf('kernel/projects/') >= 0 ||
    url.indexOf('context/connector-manager') >= 0 ||
    url.indexOf('sso/oauth-manager') >= 0 ||
    url === 'sso/auth/logout' ||
    url === 'sso/auth/me/' ||
    url === '/kernel/projects' ||
    url === 'sso/auth/sendConfirmEmail' ||
    url === '/sso/settings/configs'
  );
};

const useProjectToken = (url: string) => {
  return url === '/sso/settings/configs';
};

// For Requests
AXIOS_SERVICE.interceptors.request.use(
  async function (config) {
    if (config && config.url && tokenNeeded(config.url)) {
      if (useProjectToken(config.url)) {
        if (config.data.projectToken) {
          config.headers['X-Authorization'] = config.data.projectToken;
          delete config.data.projectToken;
        }
      } else {
        const token = await getAuthToken();
        if (token) {
          config.headers['X-Authorization'] = token;
        }
        return config;
      }
    }

    return config;
  },
  function (error) {
    return error;
  },
);

// For Responses
AXIOS_SERVICE.interceptors.response.use(function (response) {
  // Add debug logging
  return response;
});

class BaseService {
  axios: any;
  constructor() {
    this.axios = AXIOS_SERVICE;
  }
  async get(endpoint: string, data: any) {
    const response = await AXIOS_SERVICE.get(endpoint, data);
    if (response && response.headers && response.headers['x-authorization']) {
      setAuthToken(response.headers['x-authorization']);
    }
    return response;
  }

  async post(endpoint: string, data: any) {
    const response = await AXIOS_SERVICE.post(endpoint, data);
    return response;
  }

  put(endpoint: string, data: any) {
    return AXIOS_SERVICE.put(endpoint, data);
  }

  delete(endpoint: string, data: any) {
    return AXIOS_SERVICE.delete(endpoint, data);
  }

  request(type: any, url: string, data?: any) {
    let promise = null;
    switch (type) {
      case 'GET':
        promise = this.get(url, { params: data });
        break;
      case 'POST':
        promise = this.post(url, data);
        break;
      case 'PUT':
        promise = this.put(url, data);
        break;
      case 'DELETE':
        promise = this.delete(url, { params: data });
        break;
      default:
        promise = this.get(url, { params: data });
        break;
    }
    return promise;
  }
}

export default BaseService;
