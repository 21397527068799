import React, { Suspense } from 'react';
import { ControlTowerProvider } from '../contexts/ControlTowerContext';
import Routes from '../routes';

const App = () => {
  return (
    <div className="application">
      <Suspense fallback={null}>
        <ControlTowerProvider>
          <Routes />
        </ControlTowerProvider>
      </Suspense>
    </div>
  );
};

export default App;
