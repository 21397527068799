import React, { FunctionComponent, useState } from 'react';
import { FlightTextInput, FlightTextArea, FlightButton, FlightModal } from '@flybits/webapp-design-system-react';
import {
  DATASOURCE_NAME_ERROR,
  DATASOURCE_NAME_LENGTH_ERROR,
  DATASOURCE_NAME_TAKEN_ERROR,
  EMAIL_VALIDATION,
  PROVIDER_NAME_ERROR,
  DATASOURCE_GENERIC_URL_ERROR,
} from '../../constants/errors';
import {
  BASIC_INFO_SECTION_DESCRIPTION,
  BASIC_INFO_NAME_DESCRIPTION,
  BASIC_INFO_PROVIDER_NAME_DESCRIPTION,
  SUPPORT_INFO_SECTION_DESCRIPTION,
  BASIC_INFO_IMAGE_URL_DESCRIPTION,
} from '../../constants/descriptions';
import DatasourceService from '../../services/datasources.service';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import './DatasourceInfo.scss';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

interface BasicInfo {
  connectorInstanceName: string;
  providerName: string;
  description: string;
  connectorId: string;
  documentationUrl: string;
  supportEmail: string;
  imageUrl: string;
  errorsTracker?: any;    
}

interface Props {
  basicInfo: BasicInfo;
  id: string;
  connectorInstanceNameExists: boolean;
  datasourceStatus: string; 
  updateConnectorInstance: (arg: any) => void;
  setUserInput: (arg: boolean) => void;
  setBasicInfo: (arg: BasicInfo) => void;
  errorsTracker: any;
  isSaving: boolean;
}

const DatasourceInfo: FunctionComponent<Props> = (props) => {
  const { basicInfo, id, setBasicInfo, connectorInstanceNameExists,
          setUserInput, updateConnectorInstance, 
          datasourceStatus, errorsTracker, isSaving 
  } = props;  
  const history = useHistory();
  const delay = 250;

  const delayCallBack = debounce((name: string) => {
    setUserInput(true);
    setBasicInfo({ ...basicInfo, connectorInstanceName: name });
  }, delay);

  const validationSchema = Yup.object().shape({
    connectorInstanceName: Yup.string().max(80, DATASOURCE_NAME_LENGTH_ERROR).required(DATASOURCE_NAME_ERROR),
    providerName: Yup.string().required(PROVIDER_NAME_ERROR),
    documentationUrl: Yup.string().url(DATASOURCE_GENERIC_URL_ERROR),
    supportEmail: Yup.string().email(EMAIL_VALIDATION),
    imageUrl: Yup.string().url(DATASOURCE_GENERIC_URL_ERROR),
  });

  const [openCreateDeleteModal, setOpenCreateDeleteModal] = useState(false);

  const MAIN_CLASS = 'DatasourceInfo';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const MODAL_CLASS = `${MAIN_CLASS}__modal`;

  const handleDelete = (e: any) => {
    setOpenCreateDeleteModal(true);
  };

  const deleteDatasource = async () => {
    const datasourceServiceManager = new DatasourceService();
    const response = await datasourceServiceManager.deleteDatasource(id);
    if (response.status === 204) {
      history.replace('/datasources/management');
    }
  };

  const cancelDelete = () => {
    setOpenCreateDeleteModal(false);
  };

  return (
    <div className={MAIN_CLASS}>
      <FlightModal
        isVisible={openCreateDeleteModal}
        toggleModalShown={cancelDelete}
        scrollable={false}
        size="small"
        warning={false}
        className={MODAL_CLASS}
        header={<span className={`${MODAL_CLASS}__header`}>Delete {basicInfo.connectorInstanceName}</span>}
        content={
          <span>
            Deleting this datasource will stop the dataflow from projects using this datasource and remove it from
            shared projects. This cannot be undone.
          </span>
        }
        footer={
          <div className={`${MODAL_CLASS}__footer`}>
            <FlightButton
              className={`${MODAL_CLASS}__cancel-button`}
              theme="secondary"
              label="Cancel"
              onClick={cancelDelete}
            />
            <FlightButton
              className={`${MODAL_CLASS}__delete-button`}
              theme="primary"
              label="Delete Datasource"
              onClick={deleteDatasource}
            />
          </div>
        }
      />
      <Formik
        initialValues={basicInfo}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          resetForm();
          updateConnectorInstance(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <div>
            <form className={FORM_CLASS}>
              <div className={`${MAIN_CLASS}__heading-text`}>Basic info</div>
              <p className={`${MAIN_CLASS}__form-desc`}>
                {BASIC_INFO_SECTION_DESCRIPTION}{' '}
                <a href="https://flybits.com" target="_blank" rel="noopener noreferrer">
                  Learn More
                </a>
              </p>
              <div className={ITEM_CLASS}>
                <label className={`${ITEM_CLASS}__label`}>Datasource Name</label>
                <Field
                  type="text"
                  name="connectorInstanceName"
                  disabled={datasourceStatus === 'created'? false : true }
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  label=""
                  hasError={
                    (touched.connectorInstanceName && errors.connectorInstanceName) || connectorInstanceNameExists
                      ? true
                      : false
                  }
                  value={values.connectorInstanceName}
                  trailingIcon={
                    (errorsTracker.basicInfo.contentInstanceName > 0) 
                    &&
                    ((touched.connectorInstanceName && errors.connectorInstanceName) || connectorInstanceNameExists)
                      ? ''
                      : 'checkCircle'
                  }
                  errorMessage={
                    connectorInstanceNameExists ? (
                      <span>{DATASOURCE_NAME_TAKEN_ERROR}</span>
                    ) : (
                      <span>{errors.connectorInstanceName}</span>
                    )
                  }
                  onChange={(e: any) => {
                    handleChange(e);
                    delayCallBack(e.target.value);
                  }}
                  onBlur={handleBlur}
                />
                <p className={`${ITEM_CLASS}__input-desc`}>{BASIC_INFO_NAME_DESCRIPTION}</p>
              </div>
              <div className={ITEM_CLASS}>
                <label className={`${ITEM_CLASS}__label`}>Provider Name</label>
                <Field
                  type="text"
                  name="providerName"
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  label=""
                  hasError={(errorsTracker.basicInfo.providerName > 0) 
                    ||
                  (touched.providerName && errors.providerName) ? true : false}
                  value={values.providerName}
                  errorMessage={errors.providerName}
                  onChange={(e: any) => {
                    handleChange(e);
                    setBasicInfo({ ...basicInfo, providerName: e.target.value });
                  }}
                  onBlur={handleBlur}
                />
                <p className={`${ITEM_CLASS}__input-desc`}>{BASIC_INFO_PROVIDER_NAME_DESCRIPTION}</p>
              </div>
              <div className={ITEM_CLASS}>
                <label className={`${ITEM_CLASS}__label`}>Description (Optional)</label>
                <Field
                  type="textarea"
                  name="description"
                  className={`${ITEM_CLASS}__input ${ITEM_CLASS}__input-text-area`}
                  as={FlightTextArea}
                  width="100%"
                  label=""
                  hasError={touched.description && errors.description ? true : false}
                  value={values.description}
                  errorMessage={errors.description}
                  onChange={(e: any) => {
                    handleChange(e);
                    setBasicInfo({ ...basicInfo, description: e.target.value });
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div className={ITEM_CLASS}>
                <div className={`${ITEM_CLASS}__image-container`}>
                  <div className={`${ITEM_CLASS}__image-container-fields`}>
                    <label className={`${ITEM_CLASS}__label`}>Image Url</label>
                    <Field
                      type="text"
                      name="imageUrl"
                      className={`${ITEM_CLASS}__input`}
                      as={FlightTextInput}
                      width="100%"
                      label=""
                      hasError={touched.imageUrl && errors.imageUrl}
                      value={values.imageUrl}
                      errorMessage={<span>{errors.imageUrl}</span>}
                      onChange={(e: any) => {
                        handleChange(e);
                        setBasicInfo({ ...basicInfo, imageUrl: e.target.value });
                      }}
                      onBlur={handleBlur}
                    />
                    <p className={`${ITEM_CLASS}__input-desc`}>{BASIC_INFO_IMAGE_URL_DESCRIPTION}</p>
                  </div>
                  {basicInfo.imageUrl.length > 0 && (
                    <div className={`${ITEM_CLASS}__image-wrapper`}>
                      <img src={basicInfo.imageUrl} alt="identify datasource" />
                    </div>
                  )}
                  {basicInfo.imageUrl.length === 0 && (
                    <div className={`${ITEM_CLASS}__image-placeholder`}>
                      <p>No image to preview</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${MAIN_CLASS}__heading-text ${MAIN_CLASS}__heading-text-support-info`}>Support info</div>
              <p className={`${MAIN_CLASS}__form-desc`}>
                {SUPPORT_INFO_SECTION_DESCRIPTION} <a href="https://flybits.com">Learn More</a>
              </p>
              <div className={ITEM_CLASS}>
                <label className={`${ITEM_CLASS}__label`}>Documentation URL</label>
                <Field
                  type="text"
                  name="documentationUrl"
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  label=""
                  hasError={touched.documentationUrl && errors.documentationUrl}
                  value={values.documentationUrl}
                  errorMessage={<span>{errors.documentationUrl}</span>}
                  onChange={(e: any) => {
                    handleChange(e);
                    setBasicInfo({ ...basicInfo, documentationUrl: e.target.value });
                  }}
                  onBlur={handleBlur}
                />
                <p className={`${ITEM_CLASS}__input-desc`}>{BASIC_INFO_NAME_DESCRIPTION}</p>
              </div>

              <div className={ITEM_CLASS}>
                <label className={`${ITEM_CLASS}__label`}>Support Email</label>
                <Field
                  type="text"
                  name="supportEmail"
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  label=""
                  hasError={touched.supportEmail && errors.supportEmail}
                  value={values.supportEmail}
                  errorMessage={<span>{errors.supportEmail}</span>}
                  onChange={(e: any) => {
                    handleChange(e);
                    setBasicInfo({ ...basicInfo, supportEmail: e.target.value });
                  }}
                  onBlur={handleBlur}
                />
                <p className={`${ITEM_CLASS}__input-desc`}>{BASIC_INFO_NAME_DESCRIPTION}</p>
              </div>
              <div className={ITEM_CLASS}>
                <div className={`${ITEM_CLASS}__delete-datasource-wrapper`}>
                  <div className={`${MAIN_CLASS}__heading-text ${MAIN_CLASS}__heading-text-support-info`}>
                    Delete this Datasource
                  </div>
                  <p className={`${ITEM_CLASS}__input-desc`}>
                    Deleting {basicInfo.connectorInstanceName} will it from shared project listings and permantely erase
                    its configuration settings. If any users have this datasource connected, it will stop working.
                    Deleting a datasource is permanent and can&apos;t be undone.
                  </p>
                  <FlightButton
                    className=""
                    label={`Delete ${basicInfo.connectorInstanceName}`}
                    onClick={(e) => {
                      handleDelete(e);
                    }}
                  />
                </div>
              </div>

              <div>
                <FlightButton
                  label="Save Change"
                  type="submit"
                  disabled={isSaving}
                  loading={isSaving}  
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                />
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default DatasourceInfo;
