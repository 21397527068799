import React from 'react';
import './ConfigLockIcon.scss';

const SvgConfigLockIcon = (props?: any) => (
  <span className="svg-lock">
    <svg width="12" height="15" {...props} viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.99984 0.666992C3.78396 0.666992 1.99984 2.45112 1.99984 4.66699V5.33366H0.666504V14.667H11.3332V5.33366H9.99984V4.66699C9.99984 2.45112 8.21571 0.666992 5.99984 0.666992ZM5.99984 2.00033C7.5173 2.00033 8.6665 3.14953 8.6665 4.66699V5.33366H3.33317V4.66699C3.33317 3.14953 4.48238 2.00033 5.99984 2.00033ZM5.99984 8.66699C6.73317 8.66699 7.33317 9.26699 7.33317 10.0003C7.33317 10.7337 6.73317 11.3337 5.99984 11.3337C5.2665 11.3337 4.6665 10.7337 4.6665 10.0003C4.6665 9.26699 5.2665 8.66699 5.99984 8.66699Z" fill="#B4B8C3"/>
    </svg> 
    {props.name}
  </span>
);

export default SvgConfigLockIcon;
