import React, { FunctionComponent, useState, useEffect } from 'react';
import SvgFlybitsLogo from '../assets/FlybitsLogo';
import BannerImage from '../components/BannerImage/BannerImage';
import { FlightButton, FlightTextInput, FlightCheckbox, FlightModal } from '@flybits/webapp-design-system-react';
import { Link, Redirect } from 'react-router-dom';
import '../styles/pages/SignIn.scss';
import { PASSWORD_REQUIRED, EMAIL_REQUIRED, EMAIL_VALIDATION, GENERIC_INTERNAL_ERROR } from '../constants/errors';
import AuthService from '../services/authentication.service';
import {
  setStoredEmail,
  deleteStoredEmail,
  getStoredEmail,
  setStoredPassword,
  deleteStoredPassword,
  getStoredPassword,
  setAuthToken,
} from '../helpers/auth';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import { gsap, Power2 } from 'gsap';

const SignIn: FunctionComponent<{}> = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [initialValues, setInitialValues] = useState<FormValues>({ email: '', password: '' });
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(EMAIL_REQUIRED).email(EMAIL_VALIDATION),
    password: Yup.string().required(PASSWORD_REQUIRED),
  });

  interface FormValues {
    email: string;
    password: string;
  }

  const MAIN_CLASS = 'signin';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const LINK_CLASS = `${ITEM_CLASS}__link`;

  const coverTransition = async () => {
    const start = 'M 0 100 V 100 Q 50 100 100 100 V 100 z';
    const mid = 'M 0 100 V 50 Q 50 0 100 50 V 100 z';
    const end = 'M 0 100 V 0 Q 50 0 100 0 V 100 z';
    await gsap
      .timeline({ paused: false })
      .to('path#start', 0.6, {
        attr: { d: mid },
        ease: Power2.easeIn,
      })
      .to('path#start', 0.3, {
        attr: { d: end },
        ease: Power2.easeOut,
      })
      .to(`.BannerImage`, 0.6, {
        x: '-200%',
        alpha: 0,
      })
      .to('path#start', 0.3, {
        attr: { d: mid },
        ease: Power2.easeIn,
      })
      .to(`.BannerImage`, 0.3, {
        width: 0,
      })
      .to(
        'path#start',
        0.3,
        {
          attr: { d: start },
          ease: Power2.easeOut,
        },
        '-=.3',
      );
    // Hide form
    await gsap
      .timeline()
      .to(`.${FORM_CLASS} > *`, {
        duration: 0.6,
        stagger: 0.125,
        opacity: 0,
        y: -20,
      })
      .to(`.${FORM_CLASS}`, {
        duration: 1,
        opacity: 0,
      });
  };
  //Show form
  useEffect(() => {
    gsap.timeline().fromTo(
      `.signin__form > *`,
      { y: -20, opacity: 0 },
      {
        y: 0,
        duration: 0.8,
        stagger: 0.125,
        opacity: 1,
      },
      '+=.2',
    );

    const fetchEmailAndPassword = async () => {
      const emailFromStorage = await getStoredEmail();
      const initialFormValues = {
        email: '',
        password: '',
      };
      if (emailFromStorage && emailFromStorage !== 'null') {
        initialFormValues['email'] = emailFromStorage;
      }
      const passwordFromStorage = await getStoredPassword();
      if (passwordFromStorage && passwordFromStorage !== 'null') {
        initialFormValues['password'] = passwordFromStorage;
      }
      setInitialValues(initialFormValues);
    };
    fetchEmailAndPassword();
  }, []);

  const initSignIn = async (email: string, password: string) => {
    setLoading(true);
    const signInRequestObject = {
      email: email,
      password: password,
    };
    const authServiceManager = new AuthService();
    try {
      const response = await authServiceManager.signInUser(signInRequestObject);
      if (response && response.status === 200) {
        if (response.headers && response.headers['x-authorization']) {
          setAuthToken(response.headers['x-authorization']);
        }
        if (rememberMe) {
          setStoredEmail(email);
          setStoredPassword(password);
        } else {
          deleteStoredEmail();
          deleteStoredPassword();
        }
        await coverTransition();
        setIsAuthenticated(true);
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.exceptionMessage
      ) {
        setErrorMessage(error.response.data.error.exceptionMessage);
        setError(true);
      } else {
        setErrorMessage(GENERIC_INTERNAL_ERROR);
        setError(true);
      }
      console.error(error);
    }
    setLoading(false);
  };

  const ToggleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const toggleModal = () => {
    setError(false);
  };

  return (
    <div className={MAIN_CLASS}>
      <FlightModal
        isVisible={error}
        toggleModalShown={toggleModal}
        scrollable={false}
        size="small"
        warning={true}
        className="custom-modal-class"
        header={<span>Authentication Failed</span>}
        content={<span>{errorMessage}</span>}
      />
      {isAuthenticated && <Redirect to="organizations" />}
      <BannerImage />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          initSignIn(values.email, values.password);
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <div>
            <form className={FORM_CLASS}>
              <SvgFlybitsLogo width={104} height={30} viewBox="0 0 104 30" {...{ className: `${MAIN_CLASS}__logo` }} />
              <div className={`${MAIN_CLASS}__heading-text`}>Sign in</div>
              <div className={ITEM_CLASS}>
                <Field
                  type="text"
                  name="email"
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  label="Email"
                  hasError={touched.email && errors.email ? true : false}
                  value={values.email}
                  errorMessage={<span>{errors.email}</span>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={ITEM_CLASS}>
                <Field
                  type="password"
                  name="password"
                  label="Password"
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  hasError={touched.password && errors.password ? true : false}
                  value={values.password}
                  errorMessage={<span>{errors.password}</span>}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={`${ITEM_CLASS}__row`}>
                <div className={`${ITEM_CLASS}__checkbox`}>
                  <Field
                    as={FlightCheckbox}
                    value={rememberMe}
                    className={LINK_CLASS}
                    label="Remember me"
                    checkState={rememberMe ? 'SELECTED' : 'UNSELECTED'}
                    onSelect={ToggleRememberMe}
                  />
                </div>
                <div className={LINK_CLASS}>
                  <Link to="password/reset" className={LINK_CLASS}>
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <FlightButton
                className={`${ITEM_CLASS}__button`}
                type="primary"
                size="large"
                label="Sign in"
                loading={isLoading}
                disabled={isLoading || !touched.email || !!errors.email || !touched.password || !!errors.password}
                onClick={handleSubmit}
              />
              <div className={`${ITEM_CLASS}__signup`}>
                Don’t have a Flybits account?&nbsp;
                <Link to="signup" className={LINK_CLASS}>
                  Sign up
                </Link>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SignIn;
